import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import firebase from 'firebase/app';
import App from './App';
import reportWebVitals from './reportWebVitals';

firebase.initializeApp({
  apiKey: "AIzaSyBElBT0EATGzckFNUZ69M-CKcIq0VynO08",
  authDomain: "justone-7ffe6.firebaseapp.com",
  projectId: "justone-7ffe6",
  storageBucket: "justone-7ffe6.appspot.com",
  databaseURL: "https://justone-7ffe6-default-rtdb.firebaseio.com/",
  messagingSenderId: "107210378075",
  appId: "1:107210378075:web:5ad604222f2ad1d8a2687e"
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('app')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
