import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { HashRouter, Link, Redirect, Route, Switch, useHistory, useLocation, useParams } from "react-router-dom";
import queryString, { ParsedQuery } from 'query-string';
import firebase from 'firebase/app';
import 'firebase/auth';
import { Button, Card, Preloader, ProgressBar, Row, Col, TextInput, Section, Divider, Collection, CollectionItem, Badge, Icon } from 'react-materialize';
import { Game, Player, Round, User, Word } from './Models';
import { DictionaryName, words } from './Words';
import './App.css';
import 'materialize-css';
import { Dictionary, GuessableWord } from "./Dictionary";
import { arraySample } from "./util";

const Colors = {
  red: '#d50000',
  blue: '#2962ff',
  teal: '#2bbbad',
};

const Title = ({children}: {children: string}) => {
  return(
    <h2 style={{ fontWeight: 'lighter', color: Colors.teal }}>{ children }</h2>
  );
};

const Home = () => {
  return(
    <>
      <Title>Mots Interdits</Title>

      <Section>
        <Row>
          <Link to="/host" className="waves-effect waves-light btn-large" style={{ width: '100%' }}>Créer une partie</Link>
        </Row>
        <Row>
          <Link to="/join" className="waves-effect waves-light btn-large" style={{ width: '100%' }}>Joindre une partie</Link>
        </Row>
      </Section>
    </>
  );
};

enum CreationState {
  Pending,
  Creating,
  Created,
}

const Host = ({ user }: { user: User }) => {
  const [creationState, setCreationState] = useState(CreationState.Pending);
  const [game, setGame] = useState<Game | undefined>();

  useEffect(() => {
    if (creationState !== CreationState.Pending) { return; }

    setCreationState(CreationState.Creating);

    (async () => {
      setGame(await Game.create(user));

      setCreationState(CreationState.Created);
    })();
  }, []);

  if (creationState === CreationState.Created) {
    return(
      <Redirect to={ `/game/${game!.uid}` }></Redirect>
    );
  } else {
    return(
      <Loading/>
    );
  }
};

const Error = ({children}: {children: string}) => {
  return(
    <Row>
      <Card className="red accent-4">
        <span className="white-text">
          { children }
        </span>
      </Card>
    </Row>
  );
};

const Join = ({user}: {user: User}) => {
  const history = useHistory();
  const location = useLocation();

  const [gameID, setGameID] = useState<string>('');

  const joinHandler = () => {
    history.push(`/game/${ gameID }`);
  };

  const locationSearch: ParsedQuery<string> = queryString.parse(location.search);
  const error: string | null = locationSearch.error as string;

  return(
    <>
      {
        !!error && <Error>{ error }</Error>
      }

      <Row>
        <TextInput id="gameID" onChange={ event => { setGameID(event.target.value); } } label="Code de partie" noLayout />
      </Row>

      <Row className="row">
        <Button large onClick={joinHandler} disabled={ gameID.length === 0 }>
          Joindre la partie
        </Button>
      </Row>

      <Row>
        <Link to="/home">Retour</Link>
      </Row>
    </>
  );
};

const Loading = () => {
  return(
    <Row>
      <Preloader active flashing size="big" />
    </Row>
  );
};

interface GameRoute {
  gameID: string;
}

const Lobby = ({user, game}: { user: User, game: Game }) => {
  const currentPlayer = game.playerByUid(user.uid);

  useEffect(() => {
    if (currentPlayer?.uid !== user.uid && !game.isStarted()) {
      game.addPlayer(user);
    };
  }, [user.uid, game, currentPlayer?.uid]);

  if (currentPlayer) {
    return <LobbyLoaded user={user} player={currentPlayer} game={game}/>;
  } else {
    return <Loading/>;
  }
};

const LobbyPlayersList = ({ players, player, game } : { players: Array<Player>, player: Player, game: Game }) => {
  if (players.length === 0) {
    return(
      <div>
        Oops! Toujours aucun joueur dans cette équipe. Utilisez Tinder si vous avez besoin d'amis.
      </div>
    );
  }

  return(
    <Collection className="left-align">
      {
        players.map((listedPlayer) => {
          return(
            <CollectionItem key={ listedPlayer.uid }>
              { listedPlayer.name } { listedPlayer.ready && <Badge newIcon caption="Prêt!" /> }

              {
                game.isOwner(player) && listedPlayer !== player &&
                  <div className="secondary-content">
                    <a href="#" onClick={ (event: MouseEvent<HTMLAnchorElement>) => { event.preventDefault(); game.removePlayer(listedPlayer); } }>
                      <Icon>clear</Icon>
                    </a>
                  </div>
              }
            </CollectionItem>
          );
        })
      }
    </Collection>
  );
};

const LobbyLoadedOwner = ({ game }: { game: Game }) => {
  const roundLengthHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    game.update({ roundLength: parseInt(event.target.value) });
  };

  const roundCountHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    game.update({ roundCount: parseInt(event.target.value) });
  };

  const dictionaryHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    game.update({ dictionaryName: event.target.value as DictionaryName });
  };

  return(
    <>
      <Row>
        <Card>
          Invite des gens à joindre cette partie en leur partageant le code <b>{ game.uid }</b>.
        </Card>
      </Row>

      <Row>
        <div className="input-field left-align">
          <label className="browser-default" style={{ position: 'relative' }}>Dictionaire de mots</label>
          <select id="dictionary" onChange={  dictionaryHandler } value={ game.dictionaryName } className="browser-default">
            <option value="places">Destinations</option>
            <option value="simple">Devinettes Simples</option>
            <option value="forbidden">Mots Interdits</option>
          </select>
        </div>
      </Row>

      <Row>
        <div className="input-field left-align">
          <label className="browser-default" style={{ position: 'relative' }}>Durée d'une manche</label>
          <select id="roundLength" onChange={  roundLengthHandler } value={ game.roundLength.toString() } className="browser-default">
            <option value="5">5s</option>
            <option value="30">30s</option>
            <option value="60">60s</option>
            <option value="90">90s</option>
          </select>
        </div>
      </Row>

      <Row>
        <div className="input-field left-align">
          <label className="browser-default" style={{ position: 'relative' }}>Nombre de manches</label>
          <select id="roundLength" onChange={  roundCountHandler } value={ game.roundCount.toString() } className="browser-default">
            <option value="1">1</option>
            <option value="4">4</option>
            <option value="8">8</option>
            <option value="12">12</option>
            <option value="20">20</option>
          </select>
        </div>
      </Row>
    </>
  );
};

const LobbyLoadedPlayer = ({ game, player, players }: { game: Game, player: Player, players: Array<Player> }) => {
  const toggleReadinessHandler = () => {
    player.update({ ready: !player.ready });
  };

  const nameChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    player.update({ name: event.target.value });
  };

  const joinTeamHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    player.update({ team: parseInt(event.target.value) });
  };

  const startGameHandler = () => {
    game.start();
  };

  const startButtonStatus: boolean = !game.isOwner(player) || !players.every((player) => player.ready);

  return(
    <>
      <Row>
        <TextInput id="name" value={ player.name } onChange={ nameChangeHandler } disabled={ player.ready } label="Nom" noLayout />
      </Row>

      <Row>
        <div className="input-field left-align">
          <label className="browser-default" style={{ position: 'relative' }}>Équipe</label>
          <select id="team" onChange={  joinTeamHandler } value={ player.team.toString() } disabled={ player.ready } className="browser-default">
            <option value="0">Équipe Rouge</option>
            <option value="1">Équipe Bleue</option>
          </select>
        </div>
      </Row>

      <Row>
        <h5 style={{ fontWeight: 'lighter', color: Colors.red, textAlign: "left" }}>Équipe Rouge</h5>

        <LobbyPlayersList player={ player } game={ game } players={ players.filter((player) => player.team === 0) } />
      </Row>

      <Row>
        <h5 style={{ fontWeight: 'lighter', color: Colors.blue, textAlign: "left" }}>Équipe Bleue</h5>

        <LobbyPlayersList player={ player } game={ game } players={ players.filter((player) => player.team === 1) } />
      </Row>

      <Row>
        <Button large onClick={ toggleReadinessHandler } disabled={ player.name.length === 0 }>
          { player.ready ? "Pas prêt!" : "Prêt!" }
        </Button>

        <Button large onClick={ startGameHandler } disabled={ startButtonStatus } style={{ marginLeft: "10px" }}>
          Débuter!
        </Button>
      </Row>
    </>
  );
};

const LobbyLoaded = ({ user, player, game }: { user: User, player: Player, game: Game }) => {
  return(
    <>
      { game.isOwner(player) && <LobbyLoadedOwner game={ game } /> }

      <LobbyLoadedPlayer game={ game } player={ player } players={ game.allPlayers() } />

      <Row>
        <Link to="/home">Retour</Link>
      </Row>
    </>
  );
};

const RoundController = ({ player, game, round }: RoundProps) => {
  const [timeRemaining, setTimeRemaining] = useState<number>(game.roundLength * 1000);

  useEffect(() => {
    const timer = setInterval(() => {
      const remaining = round.startedAt! + (game.roundLength * 1000) - Date.now();

      setTimeRemaining(remaining);

      if (remaining <= 0) {
        clearInterval(timer);
      }
    }, 50);

    return () => { clearInterval(timer); };
  }, [round.startedAt, game.roundLength]);

  useEffect(() => {
    if (timeRemaining <= 0) {
      round.review();
    }
  }, [timeRemaining]);

  const timeRemainingInSeconds = Math.floor(timeRemaining / 1000);
  const roundOwner = game.playerByUid(round.ownerUid)!;
  const teamScores = game.teamScores();

  let component: JSX.Element;

  if (round.isOwner(player)) {
    component = <RoundOwner game={ game } round={ round } timeRemaining={ timeRemainingInSeconds } />;
  } else if (player.isOnSameTeam(roundOwner)) {
    component = <RoundTeammate roundOwner={ roundOwner } game={ game } round={ round } timeRemaining={ timeRemainingInSeconds } />;
  } else {
    component = <RoundOpponent roundOwner={ roundOwner } game={ game } round={ round } timeRemaining={ timeRemainingInSeconds } />;;
  }

  return(
    <>
      <Row>
        <h6 style={{ clear: "both" }}>
          <div style={{ color: player.team === 0 ? Colors.red : Colors.blue }} className="left left-align">
            { player.name }
          </div>

          <div className="right">
            <Badge newIcon caption={ teamScores[1].toString() } className="blue white-text" />
            <Badge newIcon caption={ teamScores[0].toString() } className="red white-text" />
          </div>
        </h6>
      </Row>

      <Divider />

      <Section>
        <h4 style={{ color: roundOwner.team === 0 ? Colors.red : Colors.blue, padding: 0, margin: 0 }}>
          { roundOwner.name }
        </h4>
        <span className="grey-text">Manche { game.rounds.length } de { game.roundCount }</span>
      </Section>

      <Section>
        { component }
      </Section>
    </>
  );
};

interface RoundProps {
  player: Player;
  game: Game;
  round: Round;
}

class RoundProgress {
  constructor(
    readonly dictionary: Dictionary,
    readonly previouslyGuessedWords: Set<string> = new Set(),
    readonly attemptedWords: Array<Word> = [],
  ) {
    this.dictionary = dictionary.exceptAll(previouslyGuessedWords);
    this.attemptedWords = attemptedWords.concat({word: this.newRandomWord().word, guessed: false});
  }

  private newRandomWord(): GuessableWord {
    return this.dictionary.randomWord();
  };

  guessed(): RoundProgress {
    return new RoundProgress(
      this.dictionary.except(this.currentGuessableWord()),
      this.previouslyGuessedWords,
      this.attemptedWords.slice(0, -1).concat({word: this.currentGuessableWord().word, guessed: true})
    );
  }

  skipped(): RoundProgress {
    return new RoundProgress(
      this.dictionary.except(this.currentGuessableWord()),
      this.previouslyGuessedWords,
      this.attemptedWords.slice(0, -1).concat({word: this.currentGuessableWord().word, guessed: false})
    );
  }

  currentGuessableWord(): GuessableWord {
    return this.dictionary.find(this.attemptedWords[this.attemptedWords.length - 1].word)!;
  }
}

interface NonOwnerRoundProps {
  roundOwner: Player;
  game: Game;
  round: Round;
  timeRemaining: number;
}

const RoundTeammate = ({ roundOwner, game, round, timeRemaining }: NonOwnerRoundProps) => {
  if (round.isPending()) {
    return(
      <Row>
        C'est à ton équipe de jouer! <b>{ roundOwner.name }</b> a <b>{ game.roundLength } secondes</b> pour vous faire deviner un maximum de mots. Bonne chance!
      </Row>
    );
  } else if (round.isRunning()) {
    return(
      <Row>
        <TimeRemaining timeRemaining={ timeRemaining } totalTime={ game.roundLength } />
      </Row>
    );
  } else {
    return(
      <Row>
        Beau travail! Ou pas. J'en sais rien. <b>{ roundOwner.name }</b> est en train de valider
        les résultats, après quoi c'est au tour de l'équipe adverse. Dites-lui de se dépêcher.
      </Row>
    );
  }

};

const associatedWords = (game: Game, round: Round): Array<string> | undefined => {
  return words(game.dictionaryName as DictionaryName).find(round.words[round.words.length - 1].word)?.associatedWords;
};

const RoundOpponent = ({ roundOwner, game, round, timeRemaining }: NonOwnerRoundProps) => {
  if (round.isPending()) {
    const funnyThingsToSay: Array<string> = [
      " C'est le temps de faire une pause pipi.",
      " C'est le temps de te servir un verre.",
      " Si t'as du lavage à faire, c'est le temps.",
      " Tournes-toi les pouces.",
      " Sois prêt pour la prochaine manche!",
      " D'ici là, t'as rien à faire."
    ];

    return(
      <Row>
        C'est à l'autre équipe de jouer. Ils ont <b>{ game.roundLength } secondes</b> pour faire deviner un maximum de mots.
        { arraySample(funnyThingsToSay) }
      </Row>
    );
  } else if (round.isRunning()) {
    return(
      <>
        <Row>
          <TimeRemaining timeRemaining={ timeRemaining } totalTime={ game.roundLength } />
        </Row>

        <ForbiddenWords words={associatedWords(game, round)} />
      </>
    );
  } else {
    return(
      <Row>
        Terminé! <b>{ roundOwner.name }</b> est en train de valider
        les résultats de son équipe, après quoi c'est votre tour!
      </Row>
    );
  }
};

interface OwnerRoundProps {
  game: Game;
  round: Round;
  timeRemaining: number;
}

const RoundOwner = ({ game, round, timeRemaining }: OwnerRoundProps) => {
  const [roundProgress, setRoundProgress] = useState<RoundProgress>(new RoundProgress(words(game.dictionaryName as DictionaryName), new Set(game.guessedWords())));

  const startGameHandler = () => {
    round.start(roundProgress.attemptedWords[0]!);
  };

  if (round.isReview()) {
    return <RoundOwnerRecap game={ game } round={ round } attemptedWords={ roundProgress.attemptedWords } />;
  } else if (round.isRunning()) {
    return <RoundOwnerRunning game={ game } round={ round } roundProgress={ roundProgress } setRoundProgress={ setRoundProgress } timeRemaining={timeRemaining} />;
  } else {
    return(
      <>
        <Row>
          C'est à ton tour. Tu as <b>{ game.roundLength } secondes</b> pour faire deviner un maximum
          de mots à ton équipe, mais attention! Vous ne pouvez mentionner aucun des mots interdits. Bonne chance!
        </Row>

        <Row>
          <Button large onClick={ startGameHandler }>
            Débuter!
          </Button>
        </Row>
      </>
    );
  }
};

interface RoundOwnerRecapProps {
  game: Game;
  round: Round;
  attemptedWords: { word: string, guessed: boolean }[];
};

const RoundOwnerRecap = ({ game, round, attemptedWords }:  RoundOwnerRecapProps) => {
  const [words, setWords] = useState<Array<{ word: string, guessed: boolean }>>(attemptedWords);

  const continueHandler = () => {
    round.complete(words);
    game.continue();
  };

  const flipWord = (flippedWord: { word: string, guessed: boolean }) => {
    setWords(words.map((word) => word.word === flippedWord.word ? { word: word.word, guessed: !flippedWord.guessed } : word));
  };

  const guessedWordsCount = words.filter((word) => word.guessed).length;

  let congratulation: JSX.Element;
  if (guessedWordsCount === 0) {
    congratulation = <>Isssshhh. Tu n'as trouvé aucun mot. Certains diront que les mots étaient trop difficiles... Entoucas.</>;
  } else if (guessedWordsCount === 1) {
    congratulation = <>Ouin. Un. Un seul mot. Bravo champion! Si jamais en plus tu t'es trompé, tu peux modifier le résultat en cliquant dessus.</>;
  } else {
    congratulation = <>Bravo champion! Tu as trouvé <b>{ guessedWordsCount }</b> mots! Si jamais tu t'es trompé, tu peux modifier un résultat en cliquant sur le mot problématique.</>;
  }

  return (
    <>
      <Row>
        { congratulation }
      </Row>

      {
        !!words.length &&
          <Row>
            <WordCollection words={ words } onClick={ flipWord } />
          </Row>
      }

      <Row>
        <Button large onClick={ continueHandler }>
          Continuer
        </Button>
      </Row>
    </>
  );
};

const WordCollection = ({ words, onClick }: { words: Array<{ word: string, guessed: boolean }>, onClick?: Function }) => {
  return(
    <Collection className="left-align">
      {
        words.map((word) => {
          return(
            <div key={ word.word } style={{ userSelect: 'none', textDecorationLine: word.guessed ? "inherit" : "line-through" }} className="collection-item" onClick={ () => { onClick && onClick(word); } }>
              { word.word }
            </div>
          );
        })
      }
    </Collection>
  );
};

interface RoundOwnerRunningProps {
  game: Game;
  round: Round;
  roundProgress: RoundProgress;
  timeRemaining: number;
  setRoundProgress: (roundProgress: RoundProgress) => void;
}

const TimeRemaining = ({timeRemaining, totalTime} : { timeRemaining: number, totalTime: number }) => {
  return(
    <>
      <h6>
        <b>{ timeRemaining } seconde{ timeRemaining > 1 ? "s" : "" }</b>
      </h6>

      <ProgressBar progress={ Math.ceil((totalTime - timeRemaining) / totalTime * 100) } />
    </>
  );
};

const RoundOwnerRunning = ({ game, round, roundProgress, timeRemaining, setRoundProgress }: RoundOwnerRunningProps) => {
  const guessedHandler = () => {
    const newRoundProgress = roundProgress.guessed();
    setRoundProgress(newRoundProgress);
    round.update({words: newRoundProgress.attemptedWords});
  };

  const skipHandler = () => {
    const newRoundProgress = roundProgress.skipped();
    setRoundProgress(newRoundProgress);
    round.update({words: newRoundProgress.attemptedWords});
  };

  return (<>
    <Row>
      <TimeRemaining totalTime={ game.roundLength } timeRemaining={ timeRemaining } />
    </Row>

    <Row>
      <Card>
        <h4 style={{ padding: 0, margin: 0 }}>{ roundProgress.currentGuessableWord().word }</h4>
      </Card>
    </Row>

    <Row>
      <Button large onClick={ guessedHandler }>
        Réussi!
      </Button>

      <Button large onClick={ skipHandler } style={{ marginLeft: "10px" }} className={ "red" }>
        Rejeter
      </Button>
    </Row>

    <Section>
      <ForbiddenWords words={roundProgress.currentGuessableWord().associatedWords} />
    </Section>
  </>);
};

const ForbiddenWords = ({ words }: { words: Array<string> | undefined }) => {
  if (words === undefined || words.length === 0) { return <></>; }

  return (
    <Row>
      {
        words.map((word) => <ForbiddenWord key={word} word={word} />)
      }
    </Row>
  );
};

const ForbiddenWord = ( { word }: { word: string }) => {
  return (
    <Card className="red lighten-2 white-text">
      <h5 style={{ padding: 0, margin: 0 }}>{ word }</h5>
    </Card>
  );
};

const GameController = ({user}: {user: User}) => {
  const { gameID } = useParams<GameRoute>();

  const [game, setGame] = useState<Game | null>(null);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const ref = Game.subscribe(gameID.toLowerCase(), (newGame) => {
      if (newGame) {
        setGame(newGame);
      } else {
        setError("Cette partie n'est plus disponible");
      }
    });

    return () => {
      ref.off();
    };
  }, [gameID]);

  if (error) {
    return(<Redirect to={ `/join?error=${error}` }></Redirect>);
  } else if (!game) {
    return (<Loading/>);
  } else if (game.isCompleted()) {
    return (<CompletedGame game={ game } user={ user } />);
  } else if (game.isStarted()) {
    const player = game.playerByUid(user.uid)!;

    if (player) {
      return <RoundController round={ game.currentRound()! } player={ player } game={ game } />;
    } else {
      return <AlreadyStartedGame />;
    }
  } else {
    return(<Lobby user={user} game={game} />);
  }
};

const CompletedGame = ({ game, user }: { game: Game, user: User }) => {
  const player: Player = game.playerByUid(user.uid)!;
  const scores: [number, number] = game.teamScores();

  const opponentTeam: number = player.team === 0 ? 1 : 0;

  let bannerComponent: JSX.Element;

  if (scores[player.team] > scores[opponentTeam]) {
    bannerComponent =
      <>
        <h1>🎉</h1>
        <Row>Félicitations! Quelle incroyable victoire! Qui aurait pu prédire un tel résultat?</Row>
      </>;
  } else if (scores[player.team] < scores[opponentTeam]) {
    bannerComponent =
      <>
        <h1>😢</h1>
        <Row>Booh! Vous avez perdu. Entre toi et moi, je crois qu'ils ont triché!</Row>
      </>;
  } else {
    bannerComponent =
      <>
        <h1>😲</h1>
        <Row>Whoa, c'est l'égalité!</Row>
      </>;
  }

  return(
    <>
      { bannerComponent }

      <Row style={{ paddingBottom: "10px" }}>
        <Col s={ 6 } style={{ paddingLeft: 0 }}>
          <h1 className="red white-text" style={{ padding: 0, margin: 0, borderRadius: "10px" }}>{ scores[0] }</h1>
        </Col>

        <Col s={ 6 } style={{ paddingRight: 0 }}>
          <h1 className="blue white-text" style={{ padding: 0, margin: 0, borderRadius: "10px" }}>{ scores[1] }</h1>
        </Col>
      </Row>

      {
        game.rounds.map((round, index) => {
          const roundOwner = game.playerByUid(round.ownerUid)!;

          return(
            <>
              <Row>
                <h6 style={{ marginTop: "5px" }}>
                  <div style={{ color: roundOwner.team === 0 ? Colors.red : Colors.blue }} className="left left-align">
                    { index + 1 }/{ game.rounds.length } • { roundOwner.name }
                  </div>

                  <div className="right">
                    <Badge newIcon caption={ `${ round.guessedWords().length }/${ round.words.length}` } className={ roundOwner.team === 0 ? "red white-text" : "blue white-text" } />
                  </div>
                </h6>
              </Row>

              <Row>
                {
                  round.words.length === 0
                    ?
                    "Aucun mot. Rien. Niet. Nada."
                    :
                    <WordCollection words={ round.words } />
                }
              </Row>
            </>
          );
        })
      }

      <Row>
        <Link to="/home">Accueil</Link>
      </Row>
    </>
  );
};

const AlreadyStartedGame = () => {
  return (
    <>
      <h1>⚠️</h1>

      <Row>
        Désolé, tes amis de t'on pas attendu, la partie est déjà en cours.
      </Row>

      <Row>
        <Link to="/home">Retour</Link>
      </Row>
    </>
  );
};

const App = () => {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    firebase.auth().signInAnonymously().then(data => setUser(data.user));
  }, []);

  if (!user) {
    return <Loading/>;
  } else {
    return (
      <HashRouter>
        <Switch>
          <Route path="/join" render={(props) => <Join {...props} user={user} />} />
          <Route path="/host" render={(props) => <Host {...props} user={user}/>}/>
          <Route path="/game/:gameID" render={(props) => <GameController {...props} user={user}/>}/>
          <Route path="/" component={Home}/>
        </Switch>
      </HashRouter>
    );
  }
};

export default App;
