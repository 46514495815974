import { Dictionary } from "../Dictionary";

export default new Dictionary([
  { word: "Abitibi", associatedWords: [] },
  { word: "Acapulco", associatedWords: [] },
  { word: "Ajaccio", associatedWords: [] },
  { word: "Alaska", associatedWords: [] },
  { word: "Allemagne", associatedWords: [] },
  { word: "Alma", associatedWords: [] },
  { word: "Alpes", associatedWords: [] },
  { word: "Amazonie", associatedWords: [] },
  { word: "Amsterdam", associatedWords: [] },
  { word: "Anchorage", associatedWords: [] },
  { word: "Angleterre", associatedWords: [] },
  { word: "Animalerie", associatedWords: [] },
  { word: "Antartique", associatedWords: [] },
  { word: "Anticosti", associatedWords: [] },
  { word: "Arabie Saoudite", associatedWords: [] },
  { word: "Arc de Triomphe", associatedWords: [] },
  { word: "Arcade", associatedWords: [] },
  { word: "Argentine", associatedWords: [] },
  { word: "Arizona", associatedWords: [] },
  { word: "Aréna", associatedWords: [] },
  { word: "Asbestos", associatedWords: [] },
  { word: "Athènes", associatedWords: [] },
  { word: "Austin", associatedWords: [] },
  { word: "Australie", associatedWords: [] },
  { word: "Aéroport", associatedWords: [] },
  { word: "Bagdad", associatedWords: [] },
  { word: "Bahamas", associatedWords: [] },
  { word: "Banff", associatedWords: [] },
  { word: "Bangkok", associatedWords: [] },
  { word: "Banque Nationale", associatedWords: [] },
  { word: "Banque Royale", associatedWords: [] },
  { word: "Bar laitier", associatedWords: [] },
  { word: "Bar", associatedWords: [] },
  { word: "Barbade", associatedWords: [] },
  { word: "Barbier", associatedWords: [] },
  { word: "Barcelone", associatedWords: [] },
  { word: "Bateau", associatedWords: [] },
  { word: "Belfast", associatedWords: [] },
  { word: "Berlin", associatedWords: [] },
  { word: "Bethléem", associatedWords: [] },
  { word: "Bibliothèque", associatedWords: [] },
  { word: "Big Ben", associatedWords: [] },
  { word: "Billard", associatedWords: [] },
  { word: "Biodome", associatedWords: [] },
  { word: "Biosphère", associatedWords: [] },
  { word: "Bolivie", associatedWords: [] },
  { word: "Bora bora", associatedWords: [] },
  { word: "Boston", associatedWords: [] },
  { word: "Boucherie", associatedWords: [] },
  { word: "Boulangerie", associatedWords: [] },
  { word: "Boulder", associatedWords: [] },
  { word: "Brasserie", associatedWords: [] },
  { word: "Bromont", associatedWords: [] },
  { word: "Bruxelles", associatedWords: [] },
  { word: "Brésil", associatedWords: [] },
  { word: "Buanderie", associatedWords: [] },
  { word: "Budapest", associatedWords: [] },
  { word: "Buenos Aires", associatedWords: [] },
  { word: "Bureau d'avocat", associatedWords: [] },
  { word: "Bureau de poste", associatedWords: [] },
  { word: "Bureau des passeports", associatedWords: [] },
  { word: "Burj Khalifa", associatedWords: [] },
  { word: "CLSC", associatedWords: [] },
  { word: "Cabane à Sucre", associatedWords: [] },
  { word: "Cabane à sucre", associatedWords: [] },
  { word: "Café", associatedWords: [] },
  { word: "Caisse populaire", associatedWords: [] },
  { word: "Californie", associatedWords: [] },
  { word: "Cambodge", associatedWords: [] },
  { word: "Camping", associatedWords: [] },
  { word: "Canal Rideau", associatedWords: [] },
  { word: "Canal de Panama", associatedWords: [] },
  { word: "Cancun", associatedWords: [] },
  { word: "Cap Canaveral", associatedWords: [] },
  { word: "Cap-Chat", associatedWords: [] },
  { word: "Carnaval de Québec", associatedWords: [] },
  { word: "Carnaval de Rio", associatedWords: [] },
  { word: "Casino", associatedWords: [] },
  { word: "Cayo Coco", associatedWords: [] },
  { word: "Central Park", associatedWords: [] },
  { word: "Centre Bell", associatedWords: [] },
  { word: "Centre commercial", associatedWords: [] },
  { word: "Centre-Ville", associatedWords: [] },
  { word: "Chalet", associatedWords: [] },
  { word: "Chamonix", associatedWords: [] },
  { word: "Charlevoix", associatedWords: [] },
  { word: "Charlottetown", associatedWords: [] },
  { word: "Chasser", associatedWords: [] },
  { word: "Chateau de Versailles", associatedWords: [] },
  { word: "Chez le docteur", associatedWords: [] },
  { word: "Chibougamau", associatedWords: [] },
  { word: "Chicago", associatedWords: [] },
  { word: "Chichén Itzá", associatedWords: [] },
  { word: "Chili", associatedWords: [] },
  { word: "Chine", associatedWords: [] },
  { word: "Chiropraticien", associatedWords: [] },
  { word: "Chocolat Favoris", associatedWords: [] },
  { word: "Chocolaterie", associatedWords: [] },
  { word: "Chutes Montmorency", associatedWords: [] },
  { word: "Château Frontenac", associatedWords: [] },
  { word: "Château Laurier", associatedWords: [] },
  { word: "Cimetière", associatedWords: [] },
  { word: "Cinque Terres", associatedWords: [] },
  { word: "Cinéma", associatedWords: [] },
  { word: "Cinéparc", associatedWords: [] },
  { word: "Club Med", associatedWords: [] },
  { word: "Colisée", associatedWords: [] },
  { word: "Colombie Britannique", associatedWords: [] },
  { word: "Colombie", associatedWords: [] },
  { word: "Colorado", associatedWords: [] },
  { word: "Compostelle", associatedWords: [] },
  { word: "Concessionnaire", associatedWords: [] },
  { word: "Copenhague", associatedWords: [] },
  { word: "Cordonnier", associatedWords: [] },
  { word: "Corse", associatedWords: [] },
  { word: "Corée du Nord", associatedWords: [] },
  { word: "Costa Rica", associatedWords: [] },
  { word: "Costco", associatedWords: [] },
  { word: "Cours de natation", associatedWords: [] },
  { word: "Couturier", associatedWords: [] },
  { word: "Croisière", associatedWords: [] },
  { word: "Crête", associatedWords: [] },
  { word: "Cuba", associatedWords: [] },
  { word: "Cueillir des pommes", associatedWords: [] },
  { word: "Côte d'Azur", associatedWords: [] },
  { word: "Côte-Nord", associatedWords: [] },
  { word: "Danser", associatedWords: [] },
  { word: "Dentiste", associatedWords: [] },
  { word: "Denver", associatedWords: [] },
  { word: "Desjardins", associatedWords: [] },
  { word: "Discothèque", associatedWords: [] },
  { word: "Dubai", associatedWords: [] },
  { word: "Dublin", associatedWords: [] },
  { word: "Dépanneur", associatedWords: [] },
  { word: "Désert du Sahara", associatedWords: [] },
  { word: "Edmonton", associatedWords: [] },
  { word: "Empire State Building", associatedWords: [] },
  { word: "Espagne", associatedWords: [] },
  { word: "Esthéticienne", associatedWords: [] },
  { word: "Estrie", associatedWords: [] },
  { word: "Faire de la motoneige", associatedWords: [] },
  { word: "Faire de la plongée", associatedWords: [] },
  { word: "Festival de Cannes", associatedWords: [] },
  { word: "Festival des Mongolfières", associatedWords: [] },
  { word: "Fleuriste", associatedWords: [] },
  { word: "Florence", associatedWords: [] },
  { word: "Floride", associatedWords: [] },
  { word: "Fontaine de Trévi", associatedWords: [] },
  { word: "Frankfurt", associatedWords: [] },
  { word: "Fruiterie", associatedWords: [] },
  { word: "Frédericton", associatedWords: [] },
  { word: "Gapsé", associatedWords: [] },
  { word: "Garage", associatedWords: [] },
  { word: "Gaspésie", associatedWords: [] },
  { word: "Glasgow", associatedWords: [] },
  { word: "Golf", associatedWords: [] },
  { word: "Grand Canyon", associatedWords: [] },
  { word: "Grand Prix", associatedWords: [] },
  { word: "Grande Muraille de Chine", associatedWords: [] },
  { word: "Groenland", associatedWords: [] },
  { word: "Ground Zero", associatedWords: [] },
  { word: "Grèce", associatedWords: [] },
  { word: "Gym", associatedWords: [] },
  { word: "Halifax", associatedWords: [] },
  { word: "Hawaii", associatedWords: [] },
  { word: "Helsinki", associatedWords: [] },
  { word: "Himalaya", associatedWords: [] },
  { word: "Hiroshima", associatedWords: [] },
  { word: "Hollande", associatedWords: [] },
  { word: "Hong Kong", associatedWords: [] },
  { word: "Honolulu", associatedWords: [] },
  { word: "Houston", associatedWords: [] },
  { word: "Hôpital", associatedWords: [] },
  { word: "Hôtel de ville", associatedWords: [] },
  { word: "Hôtel", associatedWords: [] },
  { word: "Hôtel", associatedWords: [] },
  { word: "Ikea", associatedWords: [] },
  { word: "Insectarium", associatedWords: [] },
  { word: "Iqaluit", associatedWords: [] },
  { word: "Irlande", associatedWords: [] },
  { word: "Islande", associatedWords: [] },
  { word: "Israël", associatedWords: [] },
  { word: "Istanbul", associatedWords: [] },
  { word: "Italie", associatedWords: [] },
  { word: "Japon", associatedWords: [] },
  { word: "Jardin Botannique", associatedWords: [] },
  { word: "Jeu d'évasion", associatedWords: [] },
  { word: "Jeux Olympiques", associatedWords: [] },
  { word: "Johannesburg", associatedWords: [] },
  { word: "Jérusalem", associatedWords: [] },
  { word: "Karaoké", associatedWords: [] },
  { word: "Kayak", associatedWords: [] },
  { word: "Kuujjuaq", associatedWords: [] },
  { word: "Kyoto", associatedWords: [] },
  { word: "La Baie", associatedWords: [] },
  { word: "La Grande Roue", associatedWords: [] },
  { word: "La Havane", associatedWords: [] },
  { word: "La Mecque", associatedWords: [] },
  { word: "La Ronde", associatedWords: [] },
  { word: "La Tuque", associatedWords: [] },
  { word: "Lac Louise", associatedWords: [] },
  { word: "Lac Mégantic", associatedWords: [] },
  { word: "Las Vegas", associatedWords: [] },
  { word: "Laurentides", associatedWords: [] },
  { word: "Laval", associatedWords: [] },
  { word: "Laval", associatedWords: [] },
  { word: "Lave auto", associatedWords: [] },
  { word: "Lisbonne", associatedWords: [] },
  { word: "Londres", associatedWords: [] },
  { word: "Los Angeles", associatedWords: [] },
  { word: "Lousiane", associatedWords: [] },
  { word: "Luxembourg", associatedWords: [] },
  { word: "Lévis", associatedWords: [] },
  { word: "Machu Picchu", associatedWords: [] },
  { word: "Madrid", associatedWords: [] },
  { word: "Maison Blanche", associatedWords: [] },
  { word: "Manchester", associatedWords: [] },
  { word: "Manhattan", associatedWords: [] },
  { word: "Manoir Richelieu", associatedWords: [] },
  { word: "Marché Atwater", associatedWords: [] },
  { word: "Marché Jean-Talon", associatedWords: [] },
  { word: "Marché aux puces", associatedWords: [] },
  { word: "Marina", associatedWords: [] },
  { word: "Maroc", associatedWords: [] },
  { word: "Marrakech", associatedWords: [] },
  { word: "Marseilles", associatedWords: [] },
  { word: "Massothérapeute", associatedWords: [] },
  { word: "McDonald", associatedWords: [] },
  { word: "Mer Morte", associatedWords: [] },
  { word: "Mer", associatedWords: [] },
  { word: "Mexico", associatedWords: [] },
  { word: "Mexique", associatedWords: [] },
  { word: "Miami", associatedWords: [] },
  { word: "Michigan", associatedWords: [] },
  { word: "Mini Putt", associatedWords: [] },
  { word: "Mississauga", associatedWords: [] },
  { word: "Monaco", associatedWords: [] },
  { word: "Moncton", associatedWords: [] },
  { word: "Mont Orford", associatedWords: [] },
  { word: "Mont Royal", associatedWords: [] },
  { word: "Mont Tremblant", associatedWords: [] },
  { word: "Montagne de ski", associatedWords: [] },
  { word: "Montréal", associatedWords: [] },
  { word: "Montérégie", associatedWords: [] },
  { word: "Moscou", associatedWords: [] },
  { word: "Mosquée", associatedWords: [] },
  { word: "Motel", associatedWords: [] },
  { word: "Mumbai", associatedWords: [] },
  { word: "Mur de Berlin", associatedWords: [] },
  { word: "Muse du Louvres", associatedWords: [] },
  { word: "Musée", associatedWords: [] },
  { word: "Mykonos", associatedWords: [] },
  { word: "Nanaimo", associatedWords: [] },
  { word: "Nettoyeur", associatedWords: [] },
  { word: "New Delhi", associatedWords: [] },
  { word: "New York", associatedWords: [] },
  { word: "Niagara Falls", associatedWords: [] },
  { word: "Notaire", associatedWords: [] },
  { word: "Notre-Dame de Paris", associatedWords: [] },
  { word: "Nouvelle-Zélande", associatedWords: [] },
  { word: "Nunavut", associatedWords: [] },
  { word: "Old Orchard", associatedWords: [] },
  { word: "Optométriste", associatedWords: [] },
  { word: "Opéra de Sydney", associatedWords: [] },
  { word: "Opéra", associatedWords: [] },
  { word: "Oratoire St-Joseph", associatedWords: [] },
  { word: "Orthodontiste", associatedWords: [] },
  { word: "Orthophoniste", associatedWords: [] },
  { word: "Orégon", associatedWords: [] },
  { word: "Ottawa", associatedWords: [] },
  { word: "Outaouais", associatedWords: [] },
  { word: "Pakistan", associatedWords: [] },
  { word: "Palais de Buckingham", associatedWords: [] },
  { word: "Palestine", associatedWords: [] },
  { word: "Palm Beach", associatedWords: [] },
  { word: "Parc Safari", associatedWords: [] },
  { word: "Parc à chien", associatedWords: [] },
  { word: "Parc", associatedWords: [] },
  { word: "Paris", associatedWords: [] },
  { word: "Partnénon", associatedWords: [] },
  { word: "Patinoire", associatedWords: [] },
  { word: "Patisserie", associatedWords: [] },
  { word: "Pentagone", associatedWords: [] },
  { word: "Percé", associatedWords: [] },
  { word: "Pharmacie", associatedWords: [] },
  { word: "Phoenix", associatedWords: [] },
  { word: "Physiothérapeute", associatedWords: [] },
  { word: "Piscine", associatedWords: [] },
  { word: "Pizzéria", associatedWords: [] },
  { word: "Place Rouge", associatedWords: [] },
  { word: "Place des Arts", associatedWords: [] },
  { word: "Plage", associatedWords: [] },
  { word: "Planétarium", associatedWords: [] },
  { word: "Poissonnerie", associatedWords: [] },
  { word: "Pologne", associatedWords: [] },
  { word: "Pompéii", associatedWords: [] },
  { word: "Pont Champlain", associatedWords: [] },
  { word: "Pont Jacques-Cartier", associatedWords: [] },
  { word: "Pont d'Avignon", associatedWords: [] },
  { word: "Pont du Golden Gate", associatedWords: [] },
  { word: "Port-au-Prince", associatedWords: [] },
  { word: "Portugal", associatedWords: [] },
  { word: "Poste de police", associatedWords: [] },
  { word: "Prague", associatedWords: [] },
  { word: "Promener le chien", associatedWords: [] },
  { word: "Provence", associatedWords: [] },
  { word: "Psychiatre", associatedWords: [] },
  { word: "Psychologue", associatedWords: [] },
  { word: "Puerto Plata", associatedWords: [] },
  { word: "Pyramides d'Égypte", associatedWords: [] },
  { word: "Pédalo", associatedWords: [] },
  { word: "Pédiatre", associatedWords: [] },
  { word: "Pépinière", associatedWords: [] },
  { word: "Pérou", associatedWords: [] },
  { word: "Pêche sur glace", associatedWords: [] },
  { word: "Pêcher", associatedWords: [] },
  { word: "Quartier Dix30", associatedWords: [] },
  { word: "Quilles", associatedWords: [] },
  { word: "Quincaillerie", associatedWords: [] },
  { word: "Québec", associatedWords: [] },
  { word: "Randonnée", associatedWords: [] },
  { word: "Raquette", associatedWords: [] },
  { word: "Reparateur de télé", associatedWords: [] },
  { word: "Restaurant", associatedWords: [] },
  { word: "Rimouski", associatedWords: [] },
  { word: "Rio de Janeiro", associatedWords: [] },
  { word: "Rivière du Loup", associatedWords: [] },
  { word: "Rocher Percé", associatedWords: [] },
  { word: "Rocheuses", associatedWords: [] },
  { word: "Rome", associatedWords: [] },
  { word: "Russie", associatedWords: [] },
  { word: "Régina", associatedWords: [] },
  { word: "République Dominicaine", associatedWords: [] },
  { word: "République Tchèque", associatedWords: [] },
  { word: "Réserve faunique", associatedWords: [] },
  { word: "SAAQ", associatedWords: [] },
  { word: "SAQ", associatedWords: [] },
  { word: "Saguenay Lac St-Jean", associatedWords: [] },
  { word: "Saint-Pétersbourg", associatedWords: [] },
  { word: "Salle du Royaume", associatedWords: [] },
  { word: "Salon de bronzage", associatedWords: [] },
  { word: "Salon de coiffure", associatedWords: [] },
  { word: "Salon funéraire", associatedWords: [] },
  { word: "Salt Lake City", associatedWords: [] },
  { word: "San Francisco", associatedWords: [] },
  { word: "Saskachewan", associatedWords: [] },
  { word: "Sept Iles", associatedWords: [] },
  { word: "Serrurier", associatedWords: [] },
  { word: "Shawinigan", associatedWords: [] },
  { word: "Sherbrooke", associatedWords: [] },
  { word: "Sibérie", associatedWords: [] },
  { word: "Sicile", associatedWords: [] },
  { word: "Simons", associatedWords: [] },
  { word: "Singapour", associatedWords: [] },
  { word: "Spa", associatedWords: [] },
  { word: "St-Jean", associatedWords: [] },
  { word: "St-Tite", associatedWords: [] },
  { word: "Stampede de Calgary", associatedWords: [] },
  { word: "Starbucks", associatedWords: [] },
  { word: "Station de métro", associatedWords: [] },
  { word: "Station service", associatedWords: [] },
  { word: "Statue de la Liberté", associatedWords: [] },
  { word: "Stockholm", associatedWords: [] },
  { word: "Stonehenge", associatedWords: [] },
  { word: "Subway", associatedWords: [] },
  { word: "Suisse", associatedWords: [] },
  { word: "Suède", associatedWords: [] },
  { word: "Sydney", associatedWords: [] },
  { word: "Synagogue", associatedWords: [] },
  { word: "Tailleur", associatedWords: [] },
  { word: "Taj Mahal", associatedWords: [] },
  { word: "Tampa Bay", associatedWords: [] },
  { word: "Terre-Neuve", associatedWords: [] },
  { word: "Texas", associatedWords: [] },
  { word: "Thaïlande", associatedWords: [] },
  { word: "Théatre", associatedWords: [] },
  { word: "Time Square", associatedWords: [] },
  { word: "Timmins", associatedWords: [] },
  { word: "Tokio", associatedWords: [] },
  { word: "Toronto", associatedWords: [] },
  { word: "Tour Eiffel", associatedWords: [] },
  { word: "Tour de Pise", associatedWords: [] },
  { word: "Tour du CN", associatedWords: [] },
  { word: "Travail", associatedWords: [] },
  { word: "Triangle des Bermudes", associatedWords: [] },
  { word: "Trois Rivières", associatedWords: [] },
  { word: "Tunisie", associatedWords: [] },
  { word: "Turquie", associatedWords: [] },
  { word: "Université", associatedWords: [] },
  { word: "Urgence", associatedWords: [] },
  { word: "Vancouver", associatedWords: [] },
  { word: "Varsovie", associatedWords: [] },
  { word: "Vatican", associatedWords: [] },
  { word: "Venise", associatedWords: [] },
  { word: "Vermont", associatedWords: [] },
  { word: "Victoria", associatedWords: [] },
  { word: "Vietnam", associatedWords: [] },
  { word: "Vieux Forum", associatedWords: [] },
  { word: "Vieux Port", associatedWords: [] },
  { word: "Vignoble", associatedWords: [] },
  { word: "Virginia Beach", associatedWords: [] },
  { word: "Voyage tout inclus", associatedWords: [] },
  { word: "Vénézuella", associatedWords: [] },
  { word: "Vétérinaire", associatedWords: [] },
  { word: "Walt Disney World", associatedWords: [] },
  { word: "Washington", associatedWords: [] },
  { word: "Whitehorse", associatedWords: [] },
  { word: "Winnipeg", associatedWords: [] },
  { word: "Yellowknife", associatedWords: [] },
  { word: "Yukon", associatedWords: [] },
  { word: "Zoo de Granby", associatedWords: [] },
  { word: "Zoo", associatedWords: [] },
  { word: "Zürich", associatedWords: [] },
  { word: "Écosse", associatedWords: [] },
  { word: "Édimbourg", associatedWords: [] },
  { word: "Église de Scientologie", associatedWords: [] },
  { word: "Église", associatedWords: [] },
  { word: "Épicerie", associatedWords: [] },
  { word: "Épluchette de maïs", associatedWords: [] },
  { word: "États-Unis", associatedWords: [] },
  { word: "Île d'Orléans", associatedWords: [] },
  { word: "Île de Gilligan", associatedWords: [] },
  { word: "Île du Prince-Édouard", associatedWords: [] },
  { word: "Îles Galapagos", associatedWords: [] },
  { word: "Îles de la Madeleine", associatedWords: [] },
]);
