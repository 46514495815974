import firebase from 'firebase/app';
import { DictionaryName } from './Words';
import 'firebase/database';
import { arraySample } from "./util";

export interface User {
  uid: string;
}

const randomName = (): string => {
  const potentialNames: Array<string> = [
    'Quasimodo',
    'Rococo',
    'Pistache',
    'Frimousse',
    'Boubou',
    'Boucle d\'Or',
    'Yvon Tremblay',
    'Tim Hagine',
    'Sam Pique',
    'Paul Ution',
    'Pacôme les Autres',
    'Marc des Points',
    'Lara Tatouille',
    'Kelly Diote',
    'Alan Foiré',
    'Alex Trémité',
    'Marie Rouana',
    'Aude Vaisselle',
    'Nordine Ateur',
    'Oussama Férir',
    'Phil Danstachambre',
    'Edith Orial',
    'Ella de Bonzieux',
    'Eva Pisser',
    'Sarah Croche',
    'Sylvie Bromasseur',
    'Henri Hencor',
    'Jacques Célère',
    'Jamal Partout',
    'Jean Cérien'
  ];

  return arraySample(potentialNames)!;
};

export class Player {
  constructor(
    readonly ref: firebase.database.Reference,
    readonly uid: string,
    readonly name: string,
    readonly ready: boolean,
    readonly team: number = 0
  ) {}

  async update(props: {name?: string, ready?: boolean, team?: number}): Promise<Player> {
    await this.ref.update(props);
    // @ts-ignore
    return Object.assign(new this.constructor(), { ...this, ...props });
  }

  isOnSameTeam(otherPlayer: Player) {
    return this.team === otherPlayer.team;
  }
}

const randomGameID = (): string => {
  return Math
    .random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substr(0, 4)
    .toLowerCase();
};

enum RoundState {
  Pending,
  Running,
  Review,
  Completed,
}

export class Game {
  static async create(owner: User): Promise<Game> {
    const newUid = randomGameID();
    const ref = firebase.database().ref('games/' + newUid);
    const game = new Game(ref, newUid, owner.uid, new Map(), 30, 12, DictionaryName.PLACES);
    await ref.set(GameConverter.toFirebase(game));

    return game;
  }

  static subscribe(gameUid: string, callback: (newGame: Game | null) => void): firebase.database.Reference {
    const ref = firebase.database().ref('games/' + gameUid);

    ref.on('value', (gameSnapshot) => {
      const val: GameSnapshot = gameSnapshot.val();

      if (val) {
        callback(GameConverter.fromFirebase(ref, val));
      } else {
        callback(null);
      }
    });

    return ref;
  }

  constructor(
    readonly ref: firebase.database.Reference,
    readonly uid: string,
    readonly ownerUid: string,
    readonly players: Map<string, Player>,
    readonly roundLength: number,
    readonly roundCount: number,
    readonly dictionaryName: DictionaryName,
    readonly startedAt: number | null = null,
    readonly completedAt: number | null = null,
    readonly rounds: Array<Round> = [],
  ) {}

  async update(props: { roundLength?: number, roundCount?: number, completedAt?: number, dictionaryName?: DictionaryName }): Promise<Player> {
    await this.ref.update(props);
    // @ts-ignore
    return Object.assign(new this.constructor(), { ...this, ...props });
  }

  playerByUid(uid: string): Player | undefined {
    return this.players.get(uid);
  }

  allPlayers(): Array<Player> {
    return Array.from(this.players.values());
  }

  otherPlayers(originalPlayer: Player): Array<Player> {
    return this.allPlayers().filter((player) => player.uid !== originalPlayer.uid);
  }

  teammatesOf(originalPlayer: Player): Array<Player> {
    return this.otherPlayers(originalPlayer).filter((player) => originalPlayer.isOnSameTeam(player));
  }

  opponentsOf(originalPlayer: Player): Array<Player> {
    return this.otherPlayers(originalPlayer).filter((player) => !originalPlayer.isOnSameTeam(player));
  }

  isOwner(player: Player): boolean {
    return player.uid === this.ownerUid;
  }

  isStarted(): boolean {
    return !!this.startedAt;
  }

  async addPlayer(user: User): Promise<Player> {
    if (this.isStarted()) {
      throw new Error("this game has already started");
    }

    const ref = this.ref.child(`players/${user.uid}`);

    const player = new Player(ref, user.uid, randomName(), false, this.allPlayers().length % 2);

    await ref.set(PlayerConverter.toFirebase(player));

    return player;
  }

  async removePlayer(player: Player): Promise<void> {
    if (this.isStarted()) {
      throw new Error("this game has already started");
    }

    await this.ref.child(`players/${player.uid}`).remove();
  }

  private newRound(): Round {
    let previousRoundOwnerUid: string;
    if (this.currentRound()) {
      previousRoundOwnerUid = this.currentRound()!.ownerUid!;
    } else {
      const allPlayerIds = Array.from(this.players.keys());
      previousRoundOwnerUid = arraySample(allPlayerIds)!;
    }

    const previousRoundOwner = this.playerByUid(previousRoundOwnerUid)!;
    const opponents = this.opponentsOf(previousRoundOwner);
    const teammates = this.teammatesOf(previousRoundOwner);

    let newRoundOwner: Player;
    if (opponents.length) {
      newRoundOwner = opponents[Math.floor(this.rounds.length / 2) % opponents.length];
    } else if (teammates.length) {
      newRoundOwner = teammates[Math.floor(this.rounds.length / 2) % teammates.length];
    } else {
      newRoundOwner = previousRoundOwner;
    }

    return new Round(this.ref.child("rounds").push(), newRoundOwner.uid, RoundState.Pending);
  }

  async start(): Promise<null> {
    const newRound = this.newRound();
    let rounds: { [key: string]: any } = {};
    rounds[newRound.ref.key!] = RoundConverter.toFirebase(newRound);

    return await this.ref.update({ startedAt: Date.now(), rounds: rounds });
  }

  async continue(): Promise<null> {
    if (this.rounds.length < this.roundCount) {
      const newRound = this.newRound();
      return await newRound.ref.set(RoundConverter.toFirebase(newRound));
    } else {
      await this.update({ completedAt: Date.now() });
      return null;
    }
  }

  currentRound(): Round | undefined {
    return this.rounds[this.rounds.length - 1];
  }

  words(): Array<Word> {
    return this.rounds.reduce((memo: Array<Word>, round) => memo.concat(round.words, []), []);
  }

  guessedWords(): Array<string> {
    return this.words().filter((word) => word.guessed).map((word) => word.word);
  }

  isCompleted() {
    return !!this.completedAt;
  }

  teamScores(): [number, number] {
    return(
      this.rounds.reduce((scores, round) => {
        const player = this.playerByUid(round.ownerUid)!;
        scores[player.team!] += round.guessedWords().length;
        return scores;
      }, [0, 0])
    );
  }

  teamScore(teamNumber: number): number {
    return this.teamScores()[teamNumber];
  }
}

export interface Word {
  word: string;
  guessed: boolean;
}

export class Round {
  readonly words: Array<Word>;

  constructor(
    readonly ref: firebase.database.Reference,
    readonly ownerUid: string,
    readonly status: RoundState,
    readonly startedAt?: number | null,
    readonly completedAt?: number | null,
    words?: Array<Word>,
  ) {
    this.words = words || [];
  };

  isOwner(player: Player) {
    return player.uid === this.ownerUid;
  }

  isRunning(): boolean {
    return this.status === RoundState.Running;
  }

  isCompleted(): boolean {
    return this.status === RoundState.Completed;
  }

  isPending(): boolean {
    return this.status === RoundState.Pending;
  }

  isReview(): boolean {
    return this.status === RoundState.Review;
  }

  guessedWords(): Array<string> {
    return(this.words.filter((word) => word.guessed).map((word) => word.word));
  }

  update(props: {startedAt?: number, completedAt?: number, status?: RoundState, words?: Array<Word>}): [Round, Promise<void>] {
    // @ts-ignore
    return [Object.assign(new this.constructor(), { ...this, ...props }), this.ref.update(props)];
  }

  start(word: Word): [Round, Promise<void>] {
    return this.update({ status: RoundState.Running, startedAt: Date.now(), words: [word] });
  }

  review(): [Round, Promise<void>] {
    return this.update({ status: RoundState.Review });
  }

  complete(words: Array<Word>): [Round, Promise<void>] {
    return this.update({ status: RoundState.Completed, completedAt: Date.now(), words: words });
  }
}

interface PlayerMap {
  [key: string]: PlayerSnapshot;
}

interface RoundMap {
  [key: string]: RoundSnapshot;
}

interface GameSnapshot {
  uid: string;
  ownerUid: string;
  roundLength: number;
  roundCount: number;
  players?: PlayerMap;
  rounds?: RoundMap;
  dictionaryName: DictionaryName;
  startedAt: number | null;
  completedAt: number | null;
}

const GameConverter = {
  toFirebase(game: Game): GameSnapshot {
    return { uid: game.uid, ownerUid: game.ownerUid, startedAt: game.startedAt, completedAt: game.completedAt, roundLength: game.roundLength, roundCount: game.roundCount, dictionaryName: game.dictionaryName };
  },

  fromFirebase(ref: firebase.database.Reference, data: GameSnapshot): Game {
    const players: Array<[string, Player]> =
      Object
        .entries(data.players || {})
        .map(([uid, snapshot]) => {
          return [uid, PlayerConverter.fromFirebase(ref.child(`players/${snapshot.uid}`), snapshot)];
        });

    const rounds = Object.entries(data.rounds || {}).map(([key, snapshot]) => {
      return RoundConverter.fromFirebase(ref.child(`rounds/${key}`), snapshot);
    });

    return new Game(ref, data.uid, data.ownerUid, new Map(players), data.roundLength, data.roundCount, data.dictionaryName as DictionaryName, data.startedAt, data.completedAt, rounds);
  }
};

interface PlayerSnapshot {
  uid: string;
  name: string | null;
  ready: boolean;
  team: number;
}

const PlayerConverter = {
  toFirebase(player: Player): PlayerSnapshot {
    return { uid: player.uid, name: player.name, ready: player.ready, team: player.team };
  },

  fromFirebase(ref: firebase.database.Reference, data: PlayerSnapshot): Player {
    return new Player(ref, data.uid, data.name!, data.ready, data.team);
  }
};

interface RoundSnapshot {
  ownerUid: string;
  status: RoundState;
  startedAt: number | null;
  completedAt?: number | null;
  words?: Array<Word>;
}

const RoundConverter = {
  fromFirebase(ref: firebase.database.Reference, data: RoundSnapshot): Round {
    return new Round(ref, data.ownerUid, data.status, data.startedAt, data.completedAt, data.words);
  },

  toFirebase(round: Round): RoundSnapshot {
    return { ownerUid: round.ownerUid, status: round.status, startedAt: round.startedAt || null };
  }
};
