export type GuessableWord = { word: string, associatedWords: Array<string> } ;

export class Dictionary {
  constructor(readonly words: Array<GuessableWord>) {}

  except(otherWord: GuessableWord): Dictionary {
    const newWords = this.words.filter(({ word }: { word: string }) => otherWord.word !== word);
    return new Dictionary(newWords);
  }

  exceptAll(words: Set<string>): Dictionary {
    const newWords = this.words.filter(({ word }: { word: string }) => !words.has(word));
    return new Dictionary(newWords);
  }

  randomWord(): GuessableWord  {
    return this.words[Math.floor(Math.random() * Math.floor(this.words.length))]!;
  }

  find(word: string): GuessableWord | undefined {
    return this.words.find(guessableWord => guessableWord.word === word);
  }
}
