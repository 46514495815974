import { Dictionary } from "../Dictionary";

export default new Dictionary([
  { word: "Brouillard", associatedWords: ["Bateau", "Phare", "Temps", "Brumeux", "Smog"] },
  { word: "Hibou", associatedWords: ["Sage", "Oiseau", "Hululement", "Nuit", "Percher"] },
  { word: "Jacques-Cartier", associatedWords: ["Canada", "Bateau", "1534", "Pont", "Découverte", "Explorateur"] },
  { word: "Pendu", associatedWords: ["Jeu de mots", "Deviner", "Lettres", "Bourreau", "Corde"] },
  { word: "Poli", associatedWords: ["Lisse", "Manières", "Gentil", "Courtois", "Grossier"] },
  { word: "Tipi", associatedWords: ["Amérindiens", "Tentes", "Peaux", "Maison", "Cône"] },
  { word: "Grands lacs", associatedWords: ["Supérieur", "Huron", "Michigan", "Eau", "Érié", "Ontario"] },
  { word: "Coccinelle", associatedWords: ["Insecte", "Chance", "Rouge", "Noir", "Points"] },
  { word: "Fontaine", associatedWords: ["Eau", "Couler", "Jaillir", "Boire", "Jouvence"] },
  { word: "Wagon", associatedWords: ["Train", "Locomotrice", "Queue", "Lit", "Voie ferrée"] },
  { word: "Jaguar", associatedWords: ["Voiture", "Félin", "Tacheté", "Léopard", "Luxe"] },
  { word: "Pinata", associatedWords: ["Anniversaire", "Frapper", "Bâton", "Bonbon", "Bandeau", "Mexicain"] },
  { word: "Phénix", associatedWords: ["Oiseau", "Cendres", "Harry Potter", "Feu", "Mythologie", "Renaître", "Revivre"] },
  { word: "Le Canadien", associatedWords: ["Équipe", "Hockey", "Glorieux", "Sainte-Flanelle", "Tricolore", "Carey Price"] },
  { word: "Gruau", associatedWords: ["Chaud", "Céréales", "Quaker", "Déjeuner", "Biscuit"] },
  { word: "Valet", associatedWords: ["Voiture", "Pourboire", "Serviteur", "Majordome", "Carte"] },
  { word: "Requin Blanc", associatedWords: ["Mâchoires", "Mangeur d’homme", "Aileron", "Grand", "Océan", "Dents", "Mer"] },
  { word: "Énigme", associatedWords: ["Résoudre", "Mystère", "Agatha Christie", "Roman policier", "Indice"] },
  { word: "Tortue", associatedWords: ["Lièvre", "Lent", "Course", "Carapace", "Iles Galápagos"] },
  { word: "Gouverneur", associatedWords: ["Général,", "Président", "État", "Politicien", "Lieutenant"] },
  { word: "Remorqueur", associatedWords: ["Bateau", "Port", "Navire", "Pousser", "Tirer"] },
  { word: "Querelle", associatedWords: ["Bataille", "Dispute", "Débattre", "Famille", "Entre"] },
  { word: "Lutteur", associatedWords: ["Sumo", "Japonais", "Sport", "Pousser", "Ring", "Obèse"] },
  { word: "Oignon", associatedWords: ["Pleurer", "Rondelle", "Légume", "Échalotte", "Pelure"] },
  { word: "Gorille", associatedWords: ["King Kong", "Grand", "Singe", "Chimpanzé", "Zoo", "Tarzan"] },
  { word: "Jeux Olympique", associatedWords: ["Sport", "Disciplines", "Athlètes", "Anneaux", "Flamme"] },
  { word: "Carte de souhait", associatedWords: ["Hallmark", "Message", "Vœux", "Occasion", "Pharmacie"] },
  { word: "Décalage horaire", associatedWords: ["Avion", "Fatigué", "Voyage", "Heure", "Adaptation"] },
  { word: "Chasseur de têtes", associatedWords: ["Travail", "Recruteur", "Trouver", "Talent", "Tribu"] },
  { word: "Mime", associatedWords: ["Silence", "Geste", "Muet", "Marcel", "Marceau", "Visage", "blanc"] },
  { word: "Toupet", associatedWords: ["Moumoute", "Front", "Audace", "Carré", "Cheveux"] },
  { word: "Crépuscule", associatedWords: ["Aurore", "Nuit", "Heure", "Brunante", "Aube"] },
  { word: "Nuit blance", associatedWords: ["Veiller", "Fête", "Dormir", "Fatigue", "Décalage", "Horaire"] },
  { word: "Chausson", associatedWords: ["Pomme", "Dessert", "Pâtisserie", "Bas", "Pied"] },
  { word: "Sirène", associatedWords: ["Femme", "Poisson", "Ariel", "Alarme", "Queue", "Police"] },
  { word: "Menthe", associatedWords: ["Poivrée", "Crème", "Chocolat", "Digestif", "Rafraichissant"] },
  { word: "Chorale", associatedWords: ["Chœur", "Enfant", "Chanter", "Église", "Dimanche", "Grégory"] },
  { word: "Baguette", associatedWords: ["Chinoise", "Pain", "Ustensile", "Manger", "Magique"] },
  { word: "Sombrero", associatedWords: ["Mexique", "Large", "Bord", "Paille", "Chapeau", "Mariachi"] },
  { word: "Balle", associatedWords: ["Munition", "Charger", "Fusil", "Gant", "Golf"] },
  { word: "Crayon", associatedWords: ["Efface", "HB", "Écrire", "Mine", "Aiguiser"] },
  { word: "Réparer", associatedWords: ["Dégâts", "Mécanicien", "Technicien", "Arranger", "Pots", "Cassé"] },
  { word: "Étirer", associatedWords: ["Allonger", "Exercice", "Jambes", "Élastiques", "Bras"] },
  { word: "Caisse de 24", associatedWords: ["Bière", "Bouteille", "Alcool", "Dépanneur", "Consigne"] },
  { word: "Portable", associatedWords: ["Ordinateur", "Téléphone", "Sans fil", "Petit", "Déplacement"] },
  { word: "Facteur vent", associatedWords: ["Température", "Refroidissement", "Éolien", "Hypothermie", "Sous zéro"] },
  { word: "Accélération", associatedWords: ["Vitesse", "Course", "Piste", "Voiture", "Moteur", "Lent"] },
  { word: "Proie", associatedWords: ["Prédateur", "Oiseau", "Chasse", "Victime", "Prise"] },
  { word: "Tigre", associatedWords: ["Rayures", "Félin", "Tony", "Jungle", "Shere", "Khan"] },
  { word: "Collation", associatedWords: ["Nourriture", "Manger", "Casse-croute", "Encas", "Grades"] },
  { word: "Clochard", associatedWords: ["Vagabond", "Banc", "Parc", "Sans abri", "Mendiant"] },
  { word: "Bizarre", associatedWords: ["Étrange", "Mystérieux", "Insolite", "Anormal", "Inhabituel"] },
  { word: "Fable", associatedWords: ["Épopée", "Lafontaine", "Histoire", "Morale", "Animaux", "Lièvre", "Chaperon"] },
  { word: "Gourou", associatedWords: ["Hindou", "Sage", "Spirituel", "Guide", "Secte"] },
  { word: "Colisée de rome", associatedWords: ["Arène", "Italie", "Gladiateur", "Néron", "Combats"] },
  { word: "Imperméable", associatedWords: ["Eau", "Pluie", "Protéger", "Sec", "Mouiller"] },
  { word: "Moustache", associatedWords: ["Chat", "Chien", "Barbe", "Poil", "Visage"] },
  { word: "Samuel de Champlain", associatedWords: ["Québec", "1608", "Fondation", "Nouvelle-France", "Statue", "Pont", "Montréal"] },
  { word: "Sondage", associatedWords: ["Opinion", "Pourcentage", "Gallup", "Population", "Élection"] },
  { word: "Hercule", associatedWords: ["Mythologie", "Fort", "12 Travaux", "Tâches", "Poirot"] },
  { word: "Huile d’olive", associatedWords: ["Salade", "Vinaigrette", "Méditerranée", "Liquide", "Presser"] },
  { word: "Diable de Tasmanie", associatedWords: ["Dessin animé", "Tourbillons", "Marsupial", "Dévorer", "Australie"] },
  { word: "Se plaindre", associatedWords: ["Rouspéter", "Geindre", "Mal de ventre", "Raler", "Gémir"] },
  { word: "Thomas Édison", associatedWords: ["Ampoule", "Électrique", "Inventeur", "Phonographe", "Film"] },
  { word: "Boa", associatedWords: ["Serpent", "S’enrouler", "Constrictor", "Plume", "Écharpe"] },
  { word: "Orthographe", associatedWords: ["Épeler", "Écrire", "Mots", "Lettres", "Faute"] },
  { word: "Basse", associatedWords: ["Guitare", "Haute", "Voix", "Ténor", "Note"] },
  { word: "Magicien", associatedWords: ["Tour", "Lapin", "Disparaitre", "Illusionniste", "Abracadabra"] },
  { word: "Éléphant", associatedWords: ["Blanc", "Mémoire", "Défense", "Trompe", "Dumbo", "Oreille"] },
  { word: "Souris", associatedWords: ["Mickey", "Ordinateur", "Rat", "Rongeur", "Piège"] },
  { word: "Pontage", associatedWords: ["Infarctus", "Cœur", "Chirurgie", "Coronaire", "Triple"] },
  { word: "Agent Secret", associatedWords: ["Espionnage", "Surveillance", "Infiltration", "James Bond", "Taupe"] },
  { word: "Snoopy", associatedWords: ["Beagle", "Chien", "Charlie Brown", "Niche", "Peanuts"] },
  { word: "Souffler", associatedWords: ["Vent", "Air", "Sifflement", "Gonfler", "Mais"] },
  { word: "Suçon", associatedWords: ["Cou", "Marque", "Bonbon", "Bâton", "Friandise"] },
  { word: "Alibi", associatedWords: ["Version", "Crime", "Suspect", "Inventer", "Excuse"] },
  { word: "Crosse", associatedWords: ["Sport", "Bâton", "But", "Fusil", "Amérindien"] },
  { word: "Matelas", associatedWords: ["Lit", "Ressorts", "Pneumatique", "Ferme", "Dormir"] },
  { word: "Crédit", associatedWords: ["Carte", "Argent", "Banque", "Visa", "Mastercard"] },
  { word: "Manhattan", associatedWords: ["New York", "Iles", "Grosse pomme", "Times Square", "Quartier", "Tours"] },
  { word: "Jungle", associatedWords: ["Afrique", "Forêt", "Mowgli", "Roi", "Tarzan"] },
  { word: "Kidnapper", associatedWords: ["Rançon", "Enlèvement", "Séquestrer", "Enfant", "Otage"] },
  { word: "Gateau au fromage", associatedWords: ["Dessert", "Crémeux", "Fraise", "Riche", "New York"] },
  { word: "Lait de poule", associatedWords: ["Noel", "Bol à punch", "Corsé", "Crémeux", "Brandy"] },
  { word: "Momie", associatedWords: ["Égypte", "Touankhamon", "Embaumé", "Bandelettes", "Mort"] },
  { word: "Écran", associatedWords: ["Moniteur", "Télévision", "Ordinateur", "Image", "Observer"] },
  { word: "Scalpel", associatedWords: ["Lame", "incision", "Chirurgie", "Opération", "Docteur"] },
  { word: "César", associatedWords: ["Jule", "Rome", "Empereur", "Cléopatre", "Salade"] },
  { word: "Génération", associatedWords: ["X", "Baby-boomer", "Écart", "Enfants", "Rois", "Famille"] },
  { word: "Sirop", associatedWords: ["Érable", "Toux", "Sucre", "Collant", "Cuillère à thé"] },
]);
