import { Dictionary } from "../Dictionary";

export default new Dictionary([
  { word: "Abandonné", associatedWords: [] },
  { word: "Abeille", associatedWords: [] },
  { word: "Abricot", associatedWords: [] },
  { word: "Absent", associatedWords: [] },
  { word: "Abîmé", associatedWords: [] },
  { word: "Accident", associatedWords: [] },
  { word: "Accompagner", associatedWords: [] },
  { word: "Accoucher", associatedWords: [] },
  { word: "Accrocher", associatedWords: [] },
  { word: "Acheter", associatedWords: [] },
  { word: "Acrobate", associatedWords: [] },
  { word: "Adresse", associatedWords: [] },
  { word: "Adroit", associatedWords: [] },
  { word: "Adulte", associatedWords: [] },
  { word: "Affiche", associatedWords: [] },
  { word: "Agacer", associatedWords: [] },
  { word: "Agiter", associatedWords: [] },
  { word: "Agneau", associatedWords: [] },
  { word: "Aider", associatedWords: [] },
  { word: "Aigle", associatedWords: [] },
  { word: "Aiguille", associatedWords: [] },
  { word: "Ail", associatedWords: [] },
  { word: "Aile", associatedWords: [] },
  { word: "Aimer", associatedWords: [] },
  { word: "Air", associatedWords: [] },
  { word: "Ajouter", associatedWords: [] },
  { word: "Album", associatedWords: [] },
  { word: "Aliment", associatedWords: [] },
  { word: "Aller", associatedWords: [] },
  { word: "Aller Bien", associatedWords: [] },
  { word: "Allumer", associatedWords: [] },
  { word: "Allumette", associatedWords: [] },
  { word: "Alphabet", associatedWords: [] },
  { word: "Ambulance", associatedWords: [] },
  { word: "Amener", associatedWords: [] },
  { word: "Ami", associatedWords: [] },
  { word: "Amour", associatedWords: [] },
  { word: "Ampoule", associatedWords: [] },
  { word: "Amusant", associatedWords: [] },
  { word: "An", associatedWords: [] },
  { word: "Ananas", associatedWords: [] },
  { word: "Ancien", associatedWords: [] },
  { word: "Angle", associatedWords: [] },
  { word: "Animal", associatedWords: [] },
  { word: "Animaux", associatedWords: [] },
  { word: "Anniversaire", associatedWords: [] },
  { word: "Année", associatedWords: [] },
  { word: "Anorak", associatedWords: [] },
  { word: "Appareil", associatedWords: [] },
  { word: "Appartement", associatedWords: [] },
  { word: "Appeler", associatedWords: [] },
  { word: "Apporter", associatedWords: [] },
  { word: "Appuyer", associatedWords: [] },
  { word: "Appétit", associatedWords: [] },
  { word: "Après-midi", associatedWords: [] },
  { word: "Aquarium", associatedWords: [] },
  { word: "Araignée", associatedWords: [] },
  { word: "Arbre", associatedWords: [] },
  { word: "Arc", associatedWords: [] },
  { word: "Arc-en-ciel", associatedWords: [] },
  { word: "Argent", associatedWords: [] },
  { word: "Armoire", associatedWords: [] },
  { word: "Arracher", associatedWords: [] },
  { word: "Arriver", associatedWords: [] },
  { word: "Arrière", associatedWords: [] },
  { word: "Arroser", associatedWords: [] },
  { word: "Arrosoir", associatedWords: [] },
  { word: "Arrêt", associatedWords: [] },
  { word: "Arête", associatedWords: [] },
  { word: "Ascenseur", associatedWords: [] },
  { word: "Aspirateur", associatedWords: [] },
  { word: "Assiette", associatedWords: [] },
  { word: "Assis", associatedWords: [] },
  { word: "Attacher", associatedWords: [] },
  { word: "Attendre", associatedWords: [] },
  { word: "Attention", associatedWords: [] },
  { word: "Atterrir", associatedWords: [] },
  { word: "Attraper", associatedWords: [] },
  { word: "Au Delà", associatedWords: [] },
  { word: "Au Milieu", associatedWords: [] },
  { word: "Au-delà", associatedWords: [] },
  { word: "Au-dessous", associatedWords: [] },
  { word: "Au-dessus", associatedWords: [] },
  { word: "Aujourd’hui", associatedWords: [] },
  { word: "Autant", associatedWords: [] },
  { word: "Auto", associatedWords: [] },
  { word: "Autour", associatedWords: [] },
  { word: "Avaler", associatedWords: [] },
  { word: "Avancer", associatedWords: [] },
  { word: "Avec", associatedWords: [] },
  { word: "Avion", associatedWords: [] },
  { word: "Aéroport", associatedWords: [] },
  { word: "Aîné", associatedWords: [] },
  { word: "Bagage", associatedWords: [] },
  { word: "Bagarre", associatedWords: [] },
  { word: "Bague", associatedWords: [] },
  { word: "Baguette", associatedWords: [] },
  { word: "Baigner", associatedWords: [] },
  { word: "Bain", associatedWords: [] },
  { word: "Baiser", associatedWords: [] },
  { word: "Balai", associatedWords: [] },
  { word: "Balançoire", associatedWords: [] },
  { word: "Balayer", associatedWords: [] },
  { word: "Balcon", associatedWords: [] },
  { word: "Baleine", associatedWords: [] },
  { word: "Balle", associatedWords: [] },
  { word: "Ballon", associatedWords: [] },
  { word: "Banane", associatedWords: [] },
  { word: "Banc", associatedWords: [] },
  { word: "Bande", associatedWords: [] },
  { word: "Barbe", associatedWords: [] },
  { word: "Barboter", associatedWords: [] },
  { word: "Barbouillé", associatedWords: [] },
  { word: "Barque", associatedWords: [] },
  { word: "Barre", associatedWords: [] },
  { word: "Barreau", associatedWords: [] },
  { word: "Barrer", associatedWords: [] },
  { word: "Barrette", associatedWords: [] },
  { word: "Bas", associatedWords: [] },
  { word: "Bassin", associatedWords: [] },
  { word: "Bassine", associatedWords: [] },
  { word: "Bateau", associatedWords: [] },
  { word: "Battre", associatedWords: [] },
  { word: "Baver", associatedWords: [] },
  { word: "Bavoir", associatedWords: [] },
  { word: "Beau", associatedWords: [] },
  { word: "Beaucoup", associatedWords: [] },
  { word: "Bec", associatedWords: [] },
  { word: "Belle", associatedWords: [] },
  { word: "Bercer", associatedWords: [] },
  { word: "Beurre", associatedWords: [] },
  { word: "Biberon", associatedWords: [] },
  { word: "Bicyclette", associatedWords: [] },
  { word: "Bien", associatedWords: [] },
  { word: "Bientôt", associatedWords: [] },
  { word: "Bifteck", associatedWords: [] },
  { word: "Bijou", associatedWords: [] },
  { word: "Bille", associatedWords: [] },
  { word: "Billet", associatedWords: [] },
  { word: "Biscuit", associatedWords: [] },
  { word: "Bisou", associatedWords: [] },
  { word: "Bizarre", associatedWords: [] },
  { word: "Blanc", associatedWords: [] },
  { word: "Bleu", associatedWords: [] },
  { word: "Blond", associatedWords: [] },
  { word: "Boire", associatedWords: [] },
  { word: "Bois", associatedWords: [] },
  { word: "Boisson", associatedWords: [] },
  { word: "Bol", associatedWords: [] },
  { word: "Bon", associatedWords: [] },
  { word: "Bonbon", associatedWords: [] },
  { word: "Bondir", associatedWords: [] },
  { word: "Bonhomme", associatedWords: [] },
  { word: "Bonnet", associatedWords: [] },
  { word: "Bord", associatedWords: [] },
  { word: "Bosse", associatedWords: [] },
  { word: "Bosser", associatedWords: [] },
  { word: "Botte", associatedWords: [] },
  { word: "Botte De Foin", associatedWords: [] },
  { word: "Bottes", associatedWords: [] },
  { word: "Bouche", associatedWords: [] },
  { word: "Boucher", associatedWords: [] },
  { word: "Boucherie", associatedWords: [] },
  { word: "Bouchon", associatedWords: [] },
  { word: "Bouder", associatedWords: [] },
  { word: "Boue", associatedWords: [] },
  { word: "Bouger", associatedWords: [] },
  { word: "Bouillir", associatedWords: [] },
  { word: "Boulanger", associatedWords: [] },
  { word: "Boulangerie", associatedWords: [] },
  { word: "Boule", associatedWords: [] },
  { word: "Bouquet", associatedWords: [] },
  { word: "Bourgeon", associatedWords: [] },
  { word: "Bousculer", associatedWords: [] },
  { word: "Bout", associatedWords: [] },
  { word: "Bouteille", associatedWords: [] },
  { word: "Boutique", associatedWords: [] },
  { word: "Bouton", associatedWords: [] },
  { word: "Bouée", associatedWords: [] },
  { word: "Boîte", associatedWords: [] },
  { word: "Bracelet", associatedWords: [] },
  { word: "Branche", associatedWords: [] },
  { word: "Bras", associatedWords: [] },
  { word: "Bretelle", associatedWords: [] },
  { word: "Bricolage", associatedWords: [] },
  { word: "Briller", associatedWords: [] },
  { word: "Brosse", associatedWords: [] },
  { word: "Brouette", associatedWords: [] },
  { word: "Brouillard", associatedWords: [] },
  { word: "Bruit", associatedWords: [] },
  { word: "Brun", associatedWords: [] },
  { word: "Buisson", associatedWords: [] },
  { word: "Bulles", associatedWords: [] },
  { word: "Bureau", associatedWords: [] },
  { word: "Bus", associatedWords: [] },
  { word: "Bâiller", associatedWords: [] },
  { word: "Bâton", associatedWords: [] },
  { word: "Bébé", associatedWords: [] },
  { word: "Bébés", associatedWords: [] },
  { word: "Bête", associatedWords: [] },
  { word: "Bêtes", associatedWords: [] },
  { word: "Bêtise", associatedWords: [] },
  { word: "Bûche", associatedWords: [] },
  { word: "Bœuf", associatedWords: [] },
  { word: "Cabane", associatedWords: [] },
  { word: "Cabinet", associatedWords: [] },
  { word: "Caché", associatedWords: [] },
  { word: "Cadeau", associatedWords: [] },
  { word: "Cadenas", associatedWords: [] },
  { word: "Cadre", associatedWords: [] },
  { word: "Café", associatedWords: [] },
  { word: "Cage", associatedWords: [] },
  { word: "Cagoule", associatedWords: [] },
  { word: "Caillou", associatedWords: [] },
  { word: "Caisse", associatedWords: [] },
  { word: "Calendrier", associatedWords: [] },
  { word: "Calme", associatedWords: [] },
  { word: "Camarade", associatedWords: [] },
  { word: "Camion", associatedWords: [] },
  { word: "Camp", associatedWords: [] },
  { word: "Campagne", associatedWords: [] },
  { word: "Camper", associatedWords: [] },
  { word: "Caméscope", associatedWords: [] },
  { word: "Canapé", associatedWords: [] },
  { word: "Canard", associatedWords: [] },
  { word: "Caniveau", associatedWords: [] },
  { word: "Canne", associatedWords: [] },
  { word: "Canne À Pêche", associatedWords: [] },
  { word: "Caprice", associatedWords: [] },
  { word: "Caravane", associatedWords: [] },
  { word: "Caresse", associatedWords: [] },
  { word: "Caresser", associatedWords: [] },
  { word: "Carotte", associatedWords: [] },
  { word: "Carreau", associatedWords: [] },
  { word: "Carrefour", associatedWords: [] },
  { word: "Carton", associatedWords: [] },
  { word: "Casque", associatedWords: [] },
  { word: "Casquette", associatedWords: [] },
  { word: "Casser", associatedWords: [] },
  { word: "Casserole", associatedWords: [] },
  { word: "Cassette", associatedWords: [] },
  { word: "Cassé", associatedWords: [] },
  { word: "Cauchemar", associatedWords: [] },
  { word: "Cave", associatedWords: [] },
  { word: "Ceinture", associatedWords: [] },
  { word: "Cerceau", associatedWords: [] },
  { word: "Cerf", associatedWords: [] },
  { word: "Cerf-volant", associatedWords: [] },
  { word: "Cerise", associatedWords: [] },
  { word: "Chaise", associatedWords: [] },
  { word: "Chambre", associatedWords: [] },
  { word: "Champ", associatedWords: [] },
  { word: "Champignon", associatedWords: [] },
  { word: "Chance", associatedWords: [] },
  { word: "Changeant", associatedWords: [] },
  { word: "Changer", associatedWords: [] },
  { word: "Chanson", associatedWords: [] },
  { word: "Chanter", associatedWords: [] },
  { word: "Chapeau", associatedWords: [] },
  { word: "Charcuterie", associatedWords: [] },
  { word: "Charger", associatedWords: [] },
  { word: "Chariot", associatedWords: [] },
  { word: "Chasser", associatedWords: [] },
  { word: "Chasseur", associatedWords: [] },
  { word: "Chat", associatedWords: [] },
  { word: "Chaud", associatedWords: [] },
  { word: "Chauffer", associatedWords: [] },
  { word: "Chaussette", associatedWords: [] },
  { word: "Chausson", associatedWords: [] },
  { word: "Chaussure", associatedWords: [] },
  { word: "Chaîne", associatedWords: [] },
  { word: "Chemin", associatedWords: [] },
  { word: "Cheminée", associatedWords: [] },
  { word: "Chemise", associatedWords: [] },
  { word: "Chenille", associatedWords: [] },
  { word: "Cher", associatedWords: [] },
  { word: "Chercher", associatedWords: [] },
  { word: "Cheval", associatedWords: [] },
  { word: "Cheveu", associatedWords: [] },
  { word: "Cheville", associatedWords: [] },
  { word: "Chez", associatedWords: [] },
  { word: "Chien", associatedWords: [] },
  { word: "Chiffon", associatedWords: [] },
  { word: "Chiffre", associatedWords: [] },
  { word: "Chocolat", associatedWords: [] },
  { word: "Choisir", associatedWords: [] },
  { word: "Chou", associatedWords: [] },
  { word: "Chouette", associatedWords: [] },
  { word: "Chuchoter", associatedWords: [] },
  { word: "Chute", associatedWords: [] },
  { word: "Châtaigne", associatedWords: [] },
  { word: "Château", associatedWords: [] },
  { word: "Chèvre", associatedWords: [] },
  { word: "Chêne", associatedWords: [] },
  { word: "Ciel", associatedWords: [] },
  { word: "Cigarette", associatedWords: [] },
  { word: "Cigogne", associatedWords: [] },
  { word: "Cil", associatedWords: [] },
  { word: "Cimetière", associatedWords: [] },
  { word: "Cinq", associatedWords: [] },
  { word: "Cinéma", associatedWords: [] },
  { word: "Cirque", associatedWords: [] },
  { word: "Citron", associatedWords: [] },
  { word: "Citrouille", associatedWords: [] },
  { word: "Clair", associatedWords: [] },
  { word: "Classe", associatedWords: [] },
  { word: "Clignoter", associatedWords: [] },
  { word: "Clin D’œil", associatedWords: [] },
  { word: "Cloche", associatedWords: [] },
  { word: "Clocher", associatedWords: [] },
  { word: "Clou", associatedWords: [] },
  { word: "Clown", associatedWords: [] },
  { word: "Clé", associatedWords: [] },
  { word: "Clémentine", associatedWords: [] },
  { word: "Coccinelle", associatedWords: [] },
  { word: "Cochon", associatedWords: [] },
  { word: "Cochon D’Inde", associatedWords: [] },
  { word: "Cocotte", associatedWords: [] },
  { word: "Coffre", associatedWords: [] },
  { word: "Coffret", associatedWords: [] },
  { word: "Coiffeur", associatedWords: [] },
  { word: "Coin", associatedWords: [] },
  { word: "Col", associatedWords: [] },
  { word: "Colis", associatedWords: [] },
  { word: "Collant", associatedWords: [] },
  { word: "Colle", associatedWords: [] },
  { word: "Coller", associatedWords: [] },
  { word: "Collier", associatedWords: [] },
  { word: "Colline", associatedWords: [] },
  { word: "Colorier", associatedWords: [] },
  { word: "Colère", associatedWords: [] },
  { word: "Commencer", associatedWords: [] },
  { word: "Comparer", associatedWords: [] },
  { word: "Compter", associatedWords: [] },
  { word: "Concombre", associatedWords: [] },
  { word: "Conduire", associatedWords: [] },
  { word: "Confiture", associatedWords: [] },
  { word: "Confortable", associatedWords: [] },
  { word: "Consoler", associatedWords: [] },
  { word: "Construire", associatedWords: [] },
  { word: "Content", associatedWords: [] },
  { word: "Continuer", associatedWords: [] },
  { word: "Contraire", associatedWords: [] },
  { word: "Copain", associatedWords: [] },
  { word: "Copier", associatedWords: [] },
  { word: "Coq", associatedWords: [] },
  { word: "Coquelicot", associatedWords: [] },
  { word: "Coquet", associatedWords: [] },
  { word: "Coquillage", associatedWords: [] },
  { word: "Coquille", associatedWords: [] },
  { word: "Coquin", associatedWords: [] },
  { word: "Corbeau", associatedWords: [] },
  { word: "Corbeille", associatedWords: [] },
  { word: "Corde", associatedWords: [] },
  { word: "Corps", associatedWords: [] },
  { word: "Cou", associatedWords: [] },
  { word: "Couché", associatedWords: [] },
  { word: "Coude", associatedWords: [] },
  { word: "Coudre", associatedWords: [] },
  { word: "Couette", associatedWords: [] },
  { word: "Couloir", associatedWords: [] },
  { word: "Coup", associatedWords: [] },
  { word: "Couper", associatedWords: [] },
  { word: "Cour", associatedWords: [] },
  { word: "Courir", associatedWords: [] },
  { word: "Couronne", associatedWords: [] },
  { word: "Courrier", associatedWords: [] },
  { word: "Course", associatedWords: [] },
  { word: "Court", associatedWords: [] },
  { word: "Cousin", associatedWords: [] },
  { word: "Cousine", associatedWords: [] },
  { word: "Coussin", associatedWords: [] },
  { word: "Couteau", associatedWords: [] },
  { word: "Couver", associatedWords: [] },
  { word: "Couvercle", associatedWords: [] },
  { word: "Couvert", associatedWords: [] },
  { word: "Couverture", associatedWords: [] },
  { word: "Coûter", associatedWords: [] },
  { word: "Crabe", associatedWords: [] },
  { word: "Cracher", associatedWords: [] },
  { word: "Crapaud", associatedWords: [] },
  { word: "Cravate", associatedWords: [] },
  { word: "Creuser", associatedWords: [] },
  { word: "Creux", associatedWords: [] },
  { word: "Crevette", associatedWords: [] },
  { word: "Cri", associatedWords: [] },
  { word: "Crier", associatedWords: [] },
  { word: "Crochet", associatedWords: [] },
  { word: "Crocodile", associatedWords: [] },
  { word: "Croix", associatedWords: [] },
  { word: "Croquer", associatedWords: [] },
  { word: "Croûte", associatedWords: [] },
  { word: "Cru", associatedWords: [] },
  { word: "Cruel", associatedWords: [] },
  { word: "Crèche", associatedWords: [] },
  { word: "Crêpes", associatedWords: [] },
  { word: "Cube", associatedWords: [] },
  { word: "Cueillir", associatedWords: [] },
  { word: "Cuillère", associatedWords: [] },
  { word: "Cuire", associatedWords: [] },
  { word: "Cuisine", associatedWords: [] },
  { word: "Cuisiner", associatedWords: [] },
  { word: "Cuisinière", associatedWords: [] },
  { word: "Cuisse", associatedWords: [] },
  { word: "Cuit", associatedWords: [] },
  { word: "Culotte", associatedWords: [] },
  { word: "Curieux", associatedWords: [] },
  { word: "Cuvette", associatedWords: [] },
  { word: "Cygne", associatedWords: [] },
  { word: "Câlin", associatedWords: [] },
  { word: "Câliner", associatedWords: [] },
  { word: "Cédérom", associatedWords: [] },
  { word: "Céréale", associatedWords: [] },
  { word: "Côté", associatedWords: [] },
  { word: "Cœur", associatedWords: [] },
  { word: "Dame", associatedWords: [] },
  { word: "Danger", associatedWords: [] },
  { word: "Dangereux", associatedWords: [] },
  { word: "Danse", associatedWords: [] },
  { word: "Danser", associatedWords: [] },
  { word: "Dauphin", associatedWords: [] },
  { word: "Debout", associatedWords: [] },
  { word: "Dedans", associatedWords: [] },
  { word: "Dehors", associatedWords: [] },
  { word: "Demain", associatedWords: [] },
  { word: "Demander", associatedWords: [] },
  { word: "Demi", associatedWords: [] },
  { word: "Dent", associatedWords: [] },
  { word: "Dentifrice", associatedWords: [] },
  { word: "Dentiste", associatedWords: [] },
  { word: "Dernier", associatedWords: [] },
  { word: "Descendre", associatedWords: [] },
  { word: "Dessert", associatedWords: [] },
  { word: "Dessiner", associatedWords: [] },
  { word: "Deux", associatedWords: [] },
  { word: "Deuxième", associatedWords: [] },
  { word: "Devoir", associatedWords: [] },
  { word: "Difficile", associatedWords: [] },
  { word: "Différence", associatedWords: [] },
  { word: "Différent", associatedWords: [] },
  { word: "Dimanche", associatedWords: [] },
  { word: "Dindon", associatedWords: [] },
  { word: "Dinosaure", associatedWords: [] },
  { word: "Dire", associatedWords: [] },
  { word: "Directeur", associatedWords: [] },
  { word: "Directrice", associatedWords: [] },
  { word: "Discuter", associatedWords: [] },
  { word: "Disparaître", associatedWords: [] },
  { word: "Distribuer", associatedWords: [] },
  { word: "Dix", associatedWords: [] },
  { word: "Docteur", associatedWords: [] },
  { word: "Doigt", associatedWords: [] },
  { word: "Doigts", associatedWords: [] },
  { word: "Donner", associatedWords: [] },
  { word: "Dormir", associatedWords: [] },
  { word: "Dos", associatedWords: [] },
  { word: "Dossier", associatedWords: [] },
  { word: "Douche", associatedWords: [] },
  { word: "Doucher", associatedWords: [] },
  { word: "Douillet", associatedWords: [] },
  { word: "Doux", associatedWords: [] },
  { word: "Drap", associatedWords: [] },
  { word: "Drapeau", associatedWords: [] },
  { word: "Droit", associatedWords: [] },
  { word: "Drôle", associatedWords: [] },
  { word: "Dur", associatedWords: [] },
  { word: "Déborder", associatedWords: [] },
  { word: "Début", associatedWords: [] },
  { word: "Déchirer", associatedWords: [] },
  { word: "Décoller", associatedWords: [] },
  { word: "Décorer", associatedWords: [] },
  { word: "Découper", associatedWords: [] },
  { word: "Défendre", associatedWords: [] },
  { word: "Défiler", associatedWords: [] },
  { word: "Déguisement", associatedWords: [] },
  { word: "Déguiser", associatedWords: [] },
  { word: "Déjeuner", associatedWords: [] },
  { word: "Délicieux", associatedWords: [] },
  { word: "Démarrer", associatedWords: [] },
  { word: "Démolir", associatedWords: [] },
  { word: "Déménager", associatedWords: [] },
  { word: "Départ", associatedWords: [] },
  { word: "Dépasser", associatedWords: [] },
  { word: "Déranger", associatedWords: [] },
  { word: "Désobéir", associatedWords: [] },
  { word: "Désordre", associatedWords: [] },
  { word: "Détester", associatedWords: [] },
  { word: "Détruire", associatedWords: [] },
  { word: "Déçu", associatedWords: [] },
  { word: "Dîner", associatedWords: [] },
  { word: "Dînette", associatedWords: [] },
  { word: "D’abord", associatedWords: [] },
  { word: "Eau", associatedWords: [] },
  { word: "Effacer", associatedWords: [] },
  { word: "Effort", associatedWords: [] },
  { word: "Effrayé", associatedWords: [] },
  { word: "Embouteillage", associatedWords: [] },
  { word: "Emmener", associatedWords: [] },
  { word: "Emporter", associatedWords: [] },
  { word: "Empêcher", associatedWords: [] },
  { word: "Enceinte", associatedWords: [] },
  { word: "Encore", associatedWords: [] },
  { word: "Endive", associatedWords: [] },
  { word: "Endroit", associatedWords: [] },
  { word: "Enfant", associatedWords: [] },
  { word: "Enfermer", associatedWords: [] },
  { word: "Enfiler", associatedWords: [] },
  { word: "Enfoncer", associatedWords: [] },
  { word: "Engin", associatedWords: [] },
  { word: "Enlever", associatedWords: [] },
  { word: "Ensemble", associatedWords: [] },
  { word: "Entendre", associatedWords: [] },
  { word: "Enterrer", associatedWords: [] },
  { word: "Entier", associatedWords: [] },
  { word: "Entonnoir", associatedWords: [] },
  { word: "Entourer", associatedWords: [] },
  { word: "Entrer", associatedWords: [] },
  { word: "Entrée", associatedWords: [] },
  { word: "Enveloppe", associatedWords: [] },
  { word: "Envie", associatedWords: [] },
  { word: "Envoyer", associatedWords: [] },
  { word: "Escabeau", associatedWords: [] },
  { word: "Escalader", associatedWords: [] },
  { word: "Escalier", associatedWords: [] },
  { word: "Escargot", associatedWords: [] },
  { word: "Essayer", associatedWords: [] },
  { word: "Essence", associatedWords: [] },
  { word: "Essuyer", associatedWords: [] },
  { word: "Expliquer", associatedWords: [] },
  { word: "Extérieur", associatedWords: [] },
  { word: "Fabriquer", associatedWords: [] },
  { word: "Facile", associatedWords: [] },
  { word: "Facteur", associatedWords: [] },
  { word: "Faim", associatedWords: [] },
  { word: "Faire", associatedWords: [] },
  { word: "Faire Peur", associatedWords: [] },
  { word: "Falloir", associatedWords: [] },
  { word: "Famille", associatedWords: [] },
  { word: "Farce", associatedWords: [] },
  { word: "Farine", associatedWords: [] },
  { word: "Fatigue", associatedWords: [] },
  { word: "Faute", associatedWords: [] },
  { word: "Fauteuil", associatedWords: [] },
  { word: "Femme", associatedWords: [] },
  { word: "Fenêtre", associatedWords: [] },
  { word: "Fer À Repasser", associatedWords: [] },
  { word: "Ferme", associatedWords: [] },
  { word: "Fermer", associatedWords: [] },
  { word: "Fermier", associatedWords: [] },
  { word: "Fesse", associatedWords: [] },
  { word: "Feu", associatedWords: [] },
  { word: "Feuille", associatedWords: [] },
  { word: "Ficelle", associatedWords: [] },
  { word: "Figure", associatedWords: [] },
  { word: "Fil", associatedWords: [] },
  { word: "Filet", associatedWords: [] },
  { word: "Fille", associatedWords: [] },
  { word: "Film", associatedWords: [] },
  { word: "Fils", associatedWords: [] },
  { word: "Fin", associatedWords: [] },
  { word: "Finir", associatedWords: [] },
  { word: "Fièvre", associatedWords: [] },
  { word: "Flamme", associatedWords: [] },
  { word: "Flaque", associatedWords: [] },
  { word: "Fleur", associatedWords: [] },
  { word: "Fleuriste", associatedWords: [] },
  { word: "Flocon", associatedWords: [] },
  { word: "Flotter", associatedWords: [] },
  { word: "Flèche", associatedWords: [] },
  { word: "Foin", associatedWords: [] },
  { word: "Foire", associatedWords: [] },
  { word: "Fois", associatedWords: [] },
  { word: "Foncé", associatedWords: [] },
  { word: "Fond", associatedWords: [] },
  { word: "Fontaine", associatedWords: [] },
  { word: "Fort", associatedWords: [] },
  { word: "Forêt", associatedWords: [] },
  { word: "Fou", associatedWords: [] },
  { word: "Fouiller", associatedWords: [] },
  { word: "Four", associatedWords: [] },
  { word: "Fourchette", associatedWords: [] },
  { word: "Fourmi", associatedWords: [] },
  { word: "Fraise", associatedWords: [] },
  { word: "Framboise", associatedWords: [] },
  { word: "Frange", associatedWords: [] },
  { word: "Frapper", associatedWords: [] },
  { word: "Frein", associatedWords: [] },
  { word: "Frite", associatedWords: [] },
  { word: "Froid", associatedWords: [] },
  { word: "Fromage", associatedWords: [] },
  { word: "Front", associatedWords: [] },
  { word: "Frotter", associatedWords: [] },
  { word: "Fruit", associatedWords: [] },
  { word: "Frère", associatedWords: [] },
  { word: "Fumer", associatedWords: [] },
  { word: "Fumée", associatedWords: [] },
  { word: "Fusil", associatedWords: [] },
  { word: "Fusée", associatedWords: [] },
  { word: "Fève", associatedWords: [] },
  { word: "Fée", associatedWords: [] },
  { word: "Fête", associatedWords: [] },
  { word: "Gagner", associatedWords: [] },
  { word: "Galette", associatedWords: [] },
  { word: "Galoper", associatedWords: [] },
  { word: "Gant", associatedWords: [] },
  { word: "Garage", associatedWords: [] },
  { word: "Garder", associatedWords: [] },
  { word: "Gardien", associatedWords: [] },
  { word: "Gare", associatedWords: [] },
  { word: "Garer", associatedWords: [] },
  { word: "Garçon", associatedWords: [] },
  { word: "Gauche", associatedWords: [] },
  { word: "Geler", associatedWords: [] },
  { word: "Gelé", associatedWords: [] },
  { word: "Genou", associatedWords: [] },
  { word: "Gens", associatedWords: [] },
  { word: "Gentil", associatedWords: [] },
  { word: "Girafe", associatedWords: [] },
  { word: "Glace", associatedWords: [] },
  { word: "Glaçon", associatedWords: [] },
  { word: "Glisser", associatedWords: [] },
  { word: "Gobelet", associatedWords: [] },
  { word: "Gonfler", associatedWords: [] },
  { word: "Gorge", associatedWords: [] },
  { word: "Gourde", associatedWords: [] },
  { word: "Gourmand", associatedWords: [] },
  { word: "Goutte", associatedWords: [] },
  { word: "Gouttes", associatedWords: [] },
  { word: "Goût", associatedWords: [] },
  { word: "Goûter", associatedWords: [] },
  { word: "Grain", associatedWords: [] },
  { word: "Graine", associatedWords: [] },
  { word: "Graines", associatedWords: [] },
  { word: "Grand", associatedWords: [] },
  { word: "Grand-mère", associatedWords: [] },
  { word: "Grand-parent", associatedWords: [] },
  { word: "Grand-père", associatedWords: [] },
  { word: "Grandir", associatedWords: [] },
  { word: "Gratter", associatedWords: [] },
  { word: "Grenouille", associatedWords: [] },
  { word: "Griffe", associatedWords: [] },
  { word: "Griffer", associatedWords: [] },
  { word: "Grignoter", associatedWords: [] },
  { word: "Griller", associatedWords: [] },
  { word: "Grimace", associatedWords: [] },
  { word: "Grimper", associatedWords: [] },
  { word: "Gris", associatedWords: [] },
  { word: "Gronder", associatedWords: [] },
  { word: "Gros", associatedWords: [] },
  { word: "Grotte", associatedWords: [] },
  { word: "Groupé", associatedWords: [] },
  { word: "Grue", associatedWords: [] },
  { word: "Guetter", associatedWords: [] },
  { word: "Guirlande", associatedWords: [] },
  { word: "Guéri", associatedWords: [] },
  { word: "Guérir", associatedWords: [] },
  { word: "Guêpe", associatedWords: [] },
  { word: "Gymnastique", associatedWords: [] },
  { word: "Gâteau", associatedWords: [] },
  { word: "Géant", associatedWords: [] },
  { word: "Gêner", associatedWords: [] },
  { word: "Habit", associatedWords: [] },
  { word: "Habiter", associatedWords: [] },
  { word: "Hamster", associatedWords: [] },
  { word: "Hanche", associatedWords: [] },
  { word: "Handicapé", associatedWords: [] },
  { word: "Haricot", associatedWords: [] },
  { word: "Haut", associatedWords: [] },
  { word: "Herbe", associatedWords: [] },
  { word: "Heure", associatedWords: [] },
  { word: "Heureux", associatedWords: [] },
  { word: "Hibou", associatedWords: [] },
  { word: "Hier", associatedWords: [] },
  { word: "Hippopotame", associatedWords: [] },
  { word: "Hirondelle", associatedWords: [] },
  { word: "Hiver", associatedWords: [] },
  { word: "Homme", associatedWords: [] },
  { word: "Horloge", associatedWords: [] },
  { word: "Huile", associatedWords: [] },
  { word: "Huit", associatedWords: [] },
  { word: "Humeur", associatedWords: [] },
  { word: "Humide", associatedWords: [] },
  { word: "Hurler", associatedWords: [] },
  { word: "Hélicoptère", associatedWords: [] },
  { word: "Hérisson", associatedWords: [] },
  { word: "Hésiter", associatedWords: [] },
  { word: "Hôpital", associatedWords: [] },
  { word: "Hôtel", associatedWords: [] },
  { word: "Ici", associatedWords: [] },
  { word: "Idée", associatedWords: [] },
  { word: "Imiter", associatedWords: [] },
  { word: "Immense", associatedWords: [] },
  { word: "Immeuble", associatedWords: [] },
  { word: "Immobile", associatedWords: [] },
  { word: "Important", associatedWords: [] },
  { word: "Impossible", associatedWords: [] },
  { word: "Incendie", associatedWords: [] },
  { word: "Infirmier", associatedWords: [] },
  { word: "Infirmière", associatedWords: [] },
  { word: "Inonder", associatedWords: [] },
  { word: "Insecte", associatedWords: [] },
  { word: "Instable", associatedWords: [] },
  { word: "Installer", associatedWords: [] },
  { word: "Instrument", associatedWords: [] },
  { word: "Inséparable", associatedWords: [] },
  { word: "Intrus", associatedWords: [] },
  { word: "Intéressant", associatedWords: [] },
  { word: "Intérieur", associatedWords: [] },
  { word: "Invitation", associatedWords: [] },
  { word: "Inviter", associatedWords: [] },
  { word: "Jaloux", associatedWords: [] },
  { word: "Jamais", associatedWords: [] },
  { word: "Jambe", associatedWords: [] },
  { word: "Jambes", associatedWords: [] },
  { word: "Jambon", associatedWords: [] },
  { word: "Jardin", associatedWords: [] },
  { word: "Jardiner", associatedWords: [] },
  { word: "Jaune", associatedWords: [] },
  { word: "Jean", associatedWords: [] },
  { word: "Jeter", associatedWords: [] },
  { word: "Jeu", associatedWords: [] },
  { word: "Jeudi", associatedWords: [] },
  { word: "Jeune", associatedWords: [] },
  { word: "Joie", associatedWords: [] },
  { word: "Joli", associatedWords: [] },
  { word: "Jongler", associatedWords: [] },
  { word: "Jonquille", associatedWords: [] },
  { word: "Joue", associatedWords: [] },
  { word: "Jouer", associatedWords: [] },
  { word: "Jour", associatedWords: [] },
  { word: "Journaux", associatedWords: [] },
  { word: "Journée", associatedWords: [] },
  { word: "Joyeux", associatedWords: [] },
  { word: "Jumeau", associatedWords: [] },
  { word: "Jumelles", associatedWords: [] },
  { word: "Jupe", associatedWords: [] },
  { word: "Jus", associatedWords: [] },
  { word: "Kangourou", associatedWords: [] },
  { word: "Kiwi", associatedWords: [] },
  { word: "Maladie", associatedWords: [] },
  { word: "Lac", associatedWords: [] },
  { word: "Lacer", associatedWords: [] },
  { word: "Lacet", associatedWords: [] },
  { word: "Laine", associatedWords: [] },
  { word: "Laisse", associatedWords: [] },
  { word: "Laisser", associatedWords: [] },
  { word: "Lait", associatedWords: [] },
  { word: "Lame", associatedWords: [] },
  { word: "Lampe", associatedWords: [] },
  { word: "Lancer", associatedWords: [] },
  { word: "Langue", associatedWords: [] },
  { word: "Lapin", associatedWords: [] },
  { word: "Large", associatedWords: [] },
  { word: "Larme", associatedWords: [] },
  { word: "Lavabo", associatedWords: [] },
  { word: "Lave-linge", associatedWords: [] },
  { word: "Laver", associatedWords: [] },
  { word: "Lecture", associatedWords: [] },
  { word: "Lent", associatedWords: [] },
  { word: "Lessive", associatedWords: [] },
  { word: "Lettre", associatedWords: [] },
  { word: "Lever", associatedWords: [] },
  { word: "Linge", associatedWords: [] },
  { word: "Lion", associatedWords: [] },
  { word: "Liquide", associatedWords: [] },
  { word: "Lire", associatedWords: [] },
  { word: "Lisse", associatedWords: [] },
  { word: "Liste", associatedWords: [] },
  { word: "Lit", associatedWords: [] },
  { word: "Litre", associatedWords: [] },
  { word: "Loin", associatedWords: [] },
  { word: "Long", associatedWords: [] },
  { word: "Louche", associatedWords: [] },
  { word: "Loup", associatedWords: [] },
  { word: "Loupe", associatedWords: [] },
  { word: "Lourd", associatedWords: [] },
  { word: "Luge", associatedWords: [] },
  { word: "Lumière", associatedWords: [] },
  { word: "Lundi", associatedWords: [] },
  { word: "Lune", associatedWords: [] },
  { word: "Lunettes", associatedWords: [] },
  { word: "Lutin", associatedWords: [] },
  { word: "Là", associatedWords: [] },
  { word: "Lâcher", associatedWords: [] },
  { word: "Lèvres", associatedWords: [] },
  { word: "Lécher", associatedWords: [] },
  { word: "Léger", associatedWords: [] },
  { word: "Légume", associatedWords: [] },
  { word: "Lézard", associatedWords: [] },
  { word: "Machine", associatedWords: [] },
  { word: "Madame", associatedWords: [] },
  { word: "Magasin", associatedWords: [] },
  { word: "Magicien", associatedWords: [] },
  { word: "Magie", associatedWords: [] },
  { word: "Magnétoscope", associatedWords: [] },
  { word: "Maigre", associatedWords: [] },
  { word: "Maillot", associatedWords: [] },
  { word: "Main", associatedWords: [] },
  { word: "Mains", associatedWords: [] },
  { word: "Maintenant", associatedWords: [] },
  { word: "Maison", associatedWords: [] },
  { word: "Mal", associatedWords: [] },
  { word: "Malade", associatedWords: [] },
  { word: "Maladie", associatedWords: [] },
  { word: "Maladroit", associatedWords: [] },
  { word: "Maman", associatedWords: [] },
  { word: "Manche", associatedWords: [] },
  { word: "Manger", associatedWords: [] },
  { word: "Manquer", associatedWords: [] },
  { word: "Manteau", associatedWords: [] },
  { word: "Manège", associatedWords: [] },
  { word: "Maquillage", associatedWords: [] },
  { word: "Maquiller", associatedWords: [] },
  { word: "Marchand", associatedWords: [] },
  { word: "Marche", associatedWords: [] },
  { word: "Marcher", associatedWords: [] },
  { word: "Marcher À Quatre Pattes", associatedWords: [] },
  { word: "Marché", associatedWords: [] },
  { word: "Mardi", associatedWords: [] },
  { word: "Mare", associatedWords: [] },
  { word: "Marguerite", associatedWords: [] },
  { word: "Mari", associatedWords: [] },
  { word: "Mariage", associatedWords: [] },
  { word: "Marin", associatedWords: [] },
  { word: "Marionnette", associatedWords: [] },
  { word: "Marron", associatedWords: [] },
  { word: "Marteau", associatedWords: [] },
  { word: "Masque", associatedWords: [] },
  { word: "Matelas", associatedWords: [] },
  { word: "Maternelle", associatedWords: [] },
  { word: "Matin", associatedWords: [] },
  { word: "Mauvais", associatedWords: [] },
  { word: "Maître", associatedWords: [] },
  { word: "Maîtresse", associatedWords: [] },
  { word: "Meilleur", associatedWords: [] },
  { word: "Melon", associatedWords: [] },
  { word: "Mensonge", associatedWords: [] },
  { word: "Menton", associatedWords: [] },
  { word: "Mer", associatedWords: [] },
  { word: "Mercredi", associatedWords: [] },
  { word: "Mesurer", associatedWords: [] },
  { word: "Mettre", associatedWords: [] },
  { word: "Meuble", associatedWords: [] },
  { word: "Micro", associatedWords: [] },
  { word: "Midi", associatedWords: [] },
  { word: "Mie", associatedWords: [] },
  { word: "Miel", associatedWords: [] },
  { word: "Mieux", associatedWords: [] },
  { word: "Milieu", associatedWords: [] },
  { word: "Mince", associatedWords: [] },
  { word: "Mine", associatedWords: [] },
  { word: "Minuit", associatedWords: [] },
  { word: "Minute", associatedWords: [] },
  { word: "Mixer", associatedWords: [] },
  { word: "Modèle", associatedWords: [] },
  { word: "Moineau", associatedWords: [] },
  { word: "Moins", associatedWords: [] },
  { word: "Mois", associatedWords: [] },
  { word: "Moitié", associatedWords: [] },
  { word: "Moment", associatedWords: [] },
  { word: "Monde", associatedWords: [] },
  { word: "Monnaie", associatedWords: [] },
  { word: "Monsieur", associatedWords: [] },
  { word: "Montagne", associatedWords: [] },
  { word: "Monter", associatedWords: [] },
  { word: "Montre", associatedWords: [] },
  { word: "Montrer", associatedWords: [] },
  { word: "Monument", associatedWords: [] },
  { word: "Morceau", associatedWords: [] },
  { word: "Mordre", associatedWords: [] },
  { word: "Mort", associatedWords: [] },
  { word: "Moteur", associatedWords: [] },
  { word: "Moto", associatedWords: [] },
  { word: "Mouche", associatedWords: [] },
  { word: "Mouchoir", associatedWords: [] },
  { word: "Mouette", associatedWords: [] },
  { word: "Moufle", associatedWords: [] },
  { word: "Mouiller", associatedWords: [] },
  { word: "Mouillé", associatedWords: [] },
  { word: "Moule", associatedWords: [] },
  { word: "Moulin", associatedWords: [] },
  { word: "Mourir", associatedWords: [] },
  { word: "Mousse", associatedWords: [] },
  { word: "Moustache", associatedWords: [] },
  { word: "Moustique", associatedWords: [] },
  { word: "Mouton", associatedWords: [] },
  { word: "Moyen", associatedWords: [] },
  { word: "Muet", associatedWords: [] },
  { word: "Muguet", associatedWords: [] },
  { word: "Multicolore", associatedWords: [] },
  { word: "Mur", associatedWords: [] },
  { word: "Mur D’escalade", associatedWords: [] },
  { word: "Muscle", associatedWords: [] },
  { word: "Musique", associatedWords: [] },
  { word: "Mère", associatedWords: [] },
  { word: "Mètre", associatedWords: [] },
  { word: "Méchant", associatedWords: [] },
  { word: "Médecin", associatedWords: [] },
  { word: "Médicament", associatedWords: [] },
  { word: "Mélanger", associatedWords: [] },
  { word: "Ménage", associatedWords: [] },
  { word: "Métal", associatedWords: [] },
  { word: "Météo", associatedWords: [] },
  { word: "Même", associatedWords: [] },
  { word: "Mûr", associatedWords: [] },
  { word: "Mûre", associatedWords: [] },
  { word: "Nager", associatedWords: [] },
  { word: "Nain", associatedWords: [] },
  { word: "Nappe", associatedWords: [] },
  { word: "Navet", associatedWords: [] },
  { word: "Navire", associatedWords: [] },
  { word: "Naître", associatedWords: [] },
  { word: "Neige", associatedWords: [] },
  { word: "Neiger", associatedWords: [] },
  { word: "Nettoyer", associatedWords: [] },
  { word: "Neuf", associatedWords: [] },
  { word: "Nez", associatedWords: [] },
  { word: "Nid", associatedWords: [] },
  { word: "Noir", associatedWords: [] },
  { word: "Noisette", associatedWords: [] },
  { word: "Noix", associatedWords: [] },
  { word: "Nom", associatedWords: [] },
  { word: "Nombre", associatedWords: [] },
  { word: "Nourrir", associatedWords: [] },
  { word: "Nourriture", associatedWords: [] },
  { word: "Nouveau", associatedWords: [] },
  { word: "Noyau", associatedWords: [] },
  { word: "Noël", associatedWords: [] },
  { word: "Nu", associatedWords: [] },
  { word: "Nuage", associatedWords: [] },
  { word: "Nuageux", associatedWords: [] },
  { word: "Nuit", associatedWords: [] },
  { word: "Numéro", associatedWords: [] },
  { word: "Né", associatedWords: [] },
  { word: "Nœud", associatedWords: [] },
  { word: "Objet", associatedWords: [] },
  { word: "Obliger", associatedWords: [] },
  { word: "Obéir", associatedWords: [] },
  { word: "Odeur", associatedWords: [] },
  { word: "Oeuf", associatedWords: [] },
  { word: "Offrir", associatedWords: [] },
  { word: "Ogre", associatedWords: [] },
  { word: "Oie", associatedWords: [] },
  { word: "Oignon", associatedWords: [] },
  { word: "Oiseau", associatedWords: [] },
  { word: "Ombre", associatedWords: [] },
  { word: "Ongle", associatedWords: [] },
  { word: "Or", associatedWords: [] },
  { word: "Orage", associatedWords: [] },
  { word: "Orange", associatedWords: [] },
  { word: "Orchestre", associatedWords: [] },
  { word: "Ordinateur", associatedWords: [] },
  { word: "Ordonnance", associatedWords: [] },
  { word: "Ordre", associatedWords: [] },
  { word: "Oreille", associatedWords: [] },
  { word: "Oreiller", associatedWords: [] },
  { word: "Os", associatedWords: [] },
  { word: "Oublier", associatedWords: [] },
  { word: "Ouragan", associatedWords: [] },
  { word: "Ours", associatedWords: [] },
  { word: "Outil", associatedWords: [] },
  { word: "Ouvrier", associatedWords: [] },
  { word: "Ouvrir", associatedWords: [] },
  { word: "Ouvrir Un Livre", associatedWords: [] },
  { word: "Paille", associatedWords: [] },
  { word: "Pain", associatedWords: [] },
  { word: "Paire", associatedWords: [] },
  { word: "Paix", associatedWords: [] },
  { word: "Palais", associatedWords: [] },
  { word: "Pamplemousse", associatedWords: [] },
  { word: "Panda", associatedWords: [] },
  { word: "Panier", associatedWords: [] },
  { word: "Panne", associatedWords: [] },
  { word: "Panneau", associatedWords: [] },
  { word: "Pansement", associatedWords: [] },
  { word: "Pantalon", associatedWords: [] },
  { word: "Panthère", associatedWords: [] },
  { word: "Papa", associatedWords: [] },
  { word: "Papillon", associatedWords: [] },
  { word: "Paquet", associatedWords: [] },
  { word: "Parapluie", associatedWords: [] },
  { word: "Parasol", associatedWords: [] },
  { word: "Parc", associatedWords: [] },
  { word: "Parcours", associatedWords: [] },
  { word: "Pardon", associatedWords: [] },
  { word: "Pareil", associatedWords: [] },
  { word: "Parent", associatedWords: [] },
  { word: "Parfum", associatedWords: [] },
  { word: "Parking", associatedWords: [] },
  { word: "Parler", associatedWords: [] },
  { word: "Part", associatedWords: [] },
  { word: "Partager", associatedWords: [] },
  { word: "Partie", associatedWords: [] },
  { word: "Partir", associatedWords: [] },
  { word: "Pas", associatedWords: [] },
  { word: "Passage", associatedWords: [] },
  { word: "Passer", associatedWords: [] },
  { word: "Passerelle", associatedWords: [] },
  { word: "Patauger", associatedWords: [] },
  { word: "Patient", associatedWords: [] },
  { word: "Patte", associatedWords: [] },
  { word: "Payer", associatedWords: [] },
  { word: "Pays", associatedWords: [] },
  { word: "Paysage", associatedWords: [] },
  { word: "Peau", associatedWords: [] },
  { word: "Peigne", associatedWords: [] },
  { word: "Peindre", associatedWords: [] },
  { word: "Peinture", associatedWords: [] },
  { word: "Pelle", associatedWords: [] },
  { word: "Peluche", associatedWords: [] },
  { word: "Pendule", associatedWords: [] },
  { word: "Penser", associatedWords: [] },
  { word: "Pente", associatedWords: [] },
  { word: "Percer", associatedWords: [] },
  { word: "Perdre", associatedWords: [] },
  { word: "Perroquet", associatedWords: [] },
  { word: "Persil", associatedWords: [] },
  { word: "Personne", associatedWords: [] },
  { word: "Peser", associatedWords: [] },
  { word: "Petit", associatedWords: [] },
  { word: "Petit Pois", associatedWords: [] },
  { word: "Petit-enfant", associatedWords: [] },
  { word: "Petit-fils", associatedWords: [] },
  { word: "Petite-fille", associatedWords: [] },
  { word: "Peu", associatedWords: [] },
  { word: "Peur", associatedWords: [] },
  { word: "Pharmacie", associatedWords: [] },
  { word: "Pharmacien", associatedWords: [] },
  { word: "Phoque", associatedWords: [] },
  { word: "Photo", associatedWords: [] },
  { word: "Photographier", associatedWords: [] },
  { word: "Pied", associatedWords: [] },
  { word: "Pieds", associatedWords: [] },
  { word: "Pierre", associatedWords: [] },
  { word: "Pigeon", associatedWords: [] },
  { word: "Pile", associatedWords: [] },
  { word: "Pilote", associatedWords: [] },
  { word: "Pin", associatedWords: [] },
  { word: "Pinceau", associatedWords: [] },
  { word: "Pique-niquer", associatedWords: [] },
  { word: "Piquer", associatedWords: [] },
  { word: "Piqûre", associatedWords: [] },
  { word: "Piscine", associatedWords: [] },
  { word: "Placard", associatedWords: [] },
  { word: "Place", associatedWords: [] },
  { word: "Plafond", associatedWords: [] },
  { word: "Plage", associatedWords: [] },
  { word: "Plaire", associatedWords: [] },
  { word: "Planche", associatedWords: [] },
  { word: "Plante", associatedWords: [] },
  { word: "Planter", associatedWords: [] },
  { word: "Plat", associatedWords: [] },
  { word: "Plateau", associatedWords: [] },
  { word: "Plein", associatedWords: [] },
  { word: "Pleurer", associatedWords: [] },
  { word: "Pleuvoir", associatedWords: [] },
  { word: "Plier", associatedWords: [] },
  { word: "Plongeoir", associatedWords: [] },
  { word: "Plonger", associatedWords: [] },
  { word: "Pluie", associatedWords: [] },
  { word: "Plume", associatedWords: [] },
  { word: "Plus", associatedWords: [] },
  { word: "Pluvieux", associatedWords: [] },
  { word: "Plâtre", associatedWords: [] },
  { word: "Pneu", associatedWords: [] },
  { word: "Poche", associatedWords: [] },
  { word: "Poignet", associatedWords: [] },
  { word: "Poing", associatedWords: [] },
  { word: "Point", associatedWords: [] },
  { word: "Pointu", associatedWords: [] },
  { word: "Poire", associatedWords: [] },
  { word: "Poireau", associatedWords: [] },
  { word: "Poison", associatedWords: [] },
  { word: "Poisson", associatedWords: [] },
  { word: "Poli", associatedWords: [] },
  { word: "Police", associatedWords: [] },
  { word: "Policier", associatedWords: [] },
  { word: "Pomme", associatedWords: [] },
  { word: "Pomme De Terre", associatedWords: [] },
  { word: "Pompe", associatedWords: [] },
  { word: "Pompier", associatedWords: [] },
  { word: "Poney", associatedWords: [] },
  { word: "Pont", associatedWords: [] },
  { word: "Port", associatedWords: [] },
  { word: "Porte", associatedWords: [] },
  { word: "Portemanteau", associatedWords: [] },
  { word: "Porter", associatedWords: [] },
  { word: "Portière", associatedWords: [] },
  { word: "Poser", associatedWords: [] },
  { word: "Poste", associatedWords: [] },
  { word: "Poster", associatedWords: [] },
  { word: "Pot", associatedWords: [] },
  { word: "Potage", associatedWords: [] },
  { word: "Poubelle", associatedWords: [] },
  { word: "Pouce", associatedWords: [] },
  { word: "Pouf", associatedWords: [] },
  { word: "Poule", associatedWords: [] },
  { word: "Poulet", associatedWords: [] },
  { word: "Poupée", associatedWords: [] },
  { word: "Poursuivre", associatedWords: [] },
  { word: "Pousser", associatedWords: [] },
  { word: "Poussette", associatedWords: [] },
  { word: "Poussin", associatedWords: [] },
  { word: "Poussière", associatedWords: [] },
  { word: "Poutre D’équilibre", associatedWords: [] },
  { word: "Pouvoir", associatedWords: [] },
  { word: "Poêle", associatedWords: [] },
  { word: "Prairie", associatedWords: [] },
  { word: "Premier", associatedWords: [] },
  { word: "Prendre", associatedWords: [] },
  { word: "Presque", associatedWords: [] },
  { word: "Presser", associatedWords: [] },
  { word: "Prince", associatedWords: [] },
  { word: "Princesse", associatedWords: [] },
  { word: "Prises", associatedWords: [] },
  { word: "Priver", associatedWords: [] },
  { word: "Prix", associatedWords: [] },
  { word: "Profond", associatedWords: [] },
  { word: "Promenade", associatedWords: [] },
  { word: "Promettre", associatedWords: [] },
  { word: "Propre", associatedWords: [] },
  { word: "Protéger", associatedWords: [] },
  { word: "Prudent", associatedWords: [] },
  { word: "Prune", associatedWords: [] },
  { word: "Près", associatedWords: [] },
  { word: "Préau", associatedWords: [] },
  { word: "Préférer", associatedWords: [] },
  { word: "Prénom", associatedWords: [] },
  { word: "Préparer", associatedWords: [] },
  { word: "Présent", associatedWords: [] },
  { word: "Prêt", associatedWords: [] },
  { word: "Prêter", associatedWords: [] },
  { word: "Puis", associatedWords: [] },
  { word: "Pull-over", associatedWords: [] },
  { word: "Punir", associatedWords: [] },
  { word: "Purée", associatedWords: [] },
  { word: "Pyjama", associatedWords: [] },
  { word: "Pâle", associatedWords: [] },
  { word: "Pâquerette", associatedWords: [] },
  { word: "Pâte", associatedWords: [] },
  { word: "Pâtes", associatedWords: [] },
  { word: "Pâtisserie", associatedWords: [] },
  { word: "Père", associatedWords: [] },
  { word: "Pédale", associatedWords: [] },
  { word: "Pédaler", associatedWords: [] },
  { word: "Pêche", associatedWords: [] },
  { word: "Pêcheur", associatedWords: [] },
  { word: "Quai", associatedWords: [] },
  { word: "Quartier", associatedWords: [] },
  { word: "Quatre", associatedWords: [] },
  { word: "Question", associatedWords: [] },
  { word: "Queue", associatedWords: [] },
  { word: "Raconter", associatedWords: [] },
  { word: "Radiateur", associatedWords: [] },
  { word: "Radio", associatedWords: [] },
  { word: "Radis", associatedWords: [] },
  { word: "Raisin", associatedWords: [] },
  { word: "Ramasser", associatedWords: [] },
  { word: "Ramer", associatedWords: [] },
  { word: "Rampe", associatedWords: [] },
  { word: "Ramper", associatedWords: [] },
  { word: "Ranger", associatedWords: [] },
  { word: "Rangée", associatedWords: [] },
  { word: "Rapide", associatedWords: [] },
  { word: "Raquette", associatedWords: [] },
  { word: "Rasoir", associatedWords: [] },
  { word: "Rat", associatedWords: [] },
  { word: "Rater", associatedWords: [] },
  { word: "Rayon", associatedWords: [] },
  { word: "Recette", associatedWords: [] },
  { word: "Recevoir", associatedWords: [] },
  { word: "Recommencer", associatedWords: [] },
  { word: "Reconnaître", associatedWords: [] },
  { word: "Recoudre", associatedWords: [] },
  { word: "Reculer", associatedWords: [] },
  { word: "Refuser", associatedWords: [] },
  { word: "Regard", associatedWords: [] },
  { word: "Regarder", associatedWords: [] },
  { word: "Reine", associatedWords: [] },
  { word: "Remercier", associatedWords: [] },
  { word: "Remettre", associatedWords: [] },
  { word: "Remplacer", associatedWords: [] },
  { word: "Remplir", associatedWords: [] },
  { word: "Remuer", associatedWords: [] },
  { word: "Renard", associatedWords: [] },
  { word: "Rencontrer", associatedWords: [] },
  { word: "Rendre", associatedWords: [] },
  { word: "Rentrer", associatedWords: [] },
  { word: "Rentrée", associatedWords: [] },
  { word: "Renverser", associatedWords: [] },
  { word: "Repas", associatedWords: [] },
  { word: "Repasser", associatedWords: [] },
  { word: "Requin", associatedWords: [] },
  { word: "Respirer", associatedWords: [] },
  { word: "Ressembler", associatedWords: [] },
  { word: "Restaurant", associatedWords: [] },
  { word: "Rester", associatedWords: [] },
  { word: "Retard", associatedWords: [] },
  { word: "Retarder", associatedWords: [] },
  { word: "Retour", associatedWords: [] },
  { word: "Retourner", associatedWords: [] },
  { word: "Retrouver", associatedWords: [] },
  { word: "Revenir", associatedWords: [] },
  { word: "Revoir", associatedWords: [] },
  { word: "Rhinocéros", associatedWords: [] },
  { word: "Riche", associatedWords: [] },
  { word: "Rideau", associatedWords: [] },
  { word: "Rire", associatedWords: [] },
  { word: "Rivière", associatedWords: [] },
  { word: "Riz", associatedWords: [] },
  { word: "Robe", associatedWords: [] },
  { word: "Robinet", associatedWords: [] },
  { word: "Rocher", associatedWords: [] },
  { word: "Roi", associatedWords: [] },
  { word: "Rond", associatedWords: [] },
  { word: "Rondelle", associatedWords: [] },
  { word: "Ronfler", associatedWords: [] },
  { word: "Ronger", associatedWords: [] },
  { word: "Rosé", associatedWords: [] },
  { word: "Roue", associatedWords: [] },
  { word: "Rouge", associatedWords: [] },
  { word: "Roulade", associatedWords: [] },
  { word: "Rouleau", associatedWords: [] },
  { word: "Rouler", associatedWords: [] },
  { word: "Route", associatedWords: [] },
  { word: "Roux", associatedWords: [] },
  { word: "Ruban", associatedWords: [] },
  { word: "Rue", associatedWords: [] },
  { word: "Rugueux", associatedWords: [] },
  { word: "Râpe", associatedWords: [] },
  { word: "Râper", associatedWords: [] },
  { word: "Râteau", associatedWords: [] },
  { word: "Réciter", associatedWords: [] },
  { word: "Récréation", associatedWords: [] },
  { word: "Réfrigérateur", associatedWords: [] },
  { word: "Réparer", associatedWords: [] },
  { word: "Répondre", associatedWords: [] },
  { word: "Répéter", associatedWords: [] },
  { word: "Réussir", associatedWords: [] },
  { word: "Réveil", associatedWords: [] },
  { word: "Rêver", associatedWords: [] },
  { word: "Sable", associatedWords: [] },
  { word: "Sac", associatedWords: [] },
  { word: "Sac En Plastique", associatedWords: [] },
  { word: "Sage", associatedWords: [] },
  { word: "Saigner", associatedWords: [] },
  { word: "Saison", associatedWords: [] },
  { word: "Salade", associatedWords: [] },
  { word: "Saladier", associatedWords: [] },
  { word: "Sale", associatedWords: [] },
  { word: "Salle", associatedWords: [] },
  { word: "Saluer", associatedWords: [] },
  { word: "Samedi", associatedWords: [] },
  { word: "Sang", associatedWords: [] },
  { word: "Santé", associatedWords: [] },
  { word: "Sapin", associatedWords: [] },
  { word: "Sardine", associatedWords: [] },
  { word: "Saut", associatedWords: [] },
  { word: "Sauter", associatedWords: [] },
  { word: "Savoir", associatedWords: [] },
  { word: "Savon", associatedWords: [] },
  { word: "Scie", associatedWords: [] },
  { word: "Seau", associatedWords: [] },
  { word: "Sec", associatedWords: [] },
  { word: "Secouer", associatedWords: [] },
  { word: "Sel", associatedWords: [] },
  { word: "Semaine", associatedWords: [] },
  { word: "Semelle", associatedWords: [] },
  { word: "Sens", associatedWords: [] },
  { word: "Sentir", associatedWords: [] },
  { word: "Sept", associatedWords: [] },
  { word: "Serpent", associatedWords: [] },
  { word: "Serrer", associatedWords: [] },
  { word: "Serrure", associatedWords: [] },
  { word: "Serré", associatedWords: [] },
  { word: "Serviette", associatedWords: [] },
  { word: "Servir", associatedWords: [] },
  { word: "Seul", associatedWords: [] },
  { word: "Shampoing", associatedWords: [] },
  { word: "Sieste", associatedWords: [] },
  { word: "Siffler", associatedWords: [] },
  { word: "Sifflet", associatedWords: [] },
  { word: "Signe", associatedWords: [] },
  { word: "Silence", associatedWords: [] },
  { word: "Singe", associatedWords: [] },
  { word: "Six", associatedWords: [] },
  { word: "Siège", associatedWords: [] },
  { word: "Ski", associatedWords: [] },
  { word: "Soif", associatedWords: [] },
  { word: "Soigner", associatedWords: [] },
  { word: "Soir", associatedWords: [] },
  { word: "Sol", associatedWords: [] },
  { word: "Soldat", associatedWords: [] },
  { word: "Sole", associatedWords: [] },
  { word: "Soleil", associatedWords: [] },
  { word: "Solide", associatedWords: [] },
  { word: "Sombre", associatedWords: [] },
  { word: "Sommeil", associatedWords: [] },
  { word: "Sommet", associatedWords: [] },
  { word: "Sonner", associatedWords: [] },
  { word: "Sonnette", associatedWords: [] },
  { word: "Sorcière", associatedWords: [] },
  { word: "Sortie", associatedWords: [] },
  { word: "Sortir", associatedWords: [] },
  { word: "Souffler", associatedWords: [] },
  { word: "Souffrir", associatedWords: [] },
  { word: "Souhaiter", associatedWords: [] },
  { word: "Soulever", associatedWords: [] },
  { word: "Souligner", associatedWords: [] },
  { word: "Soupe", associatedWords: [] },
  { word: "Souple", associatedWords: [] },
  { word: "Sourcil", associatedWords: [] },
  { word: "Sourd", associatedWords: [] },
  { word: "Sourire", associatedWords: [] },
  { word: "Souris", associatedWords: [] },
  { word: "Souvent", associatedWords: [] },
  { word: "Spectacle", associatedWords: [] },
  { word: "Sport", associatedWords: [] },
  { word: "Square", associatedWords: [] },
  { word: "Squelette", associatedWords: [] },
  { word: "Sucer", associatedWords: [] },
  { word: "Sucre", associatedWords: [] },
  { word: "Suivant", associatedWords: [] },
  { word: "Suivre", associatedWords: [] },
  { word: "Suivre (chronologie)", associatedWords: [] },
  { word: "Sur", associatedWords: [] },
  { word: "Surprise", associatedWords: [] },
  { word: "Surveiller", associatedWords: [] },
  { word: "Sécher", associatedWords: [] },
  { word: "Séparer", associatedWords: [] },
  { word: "Sérieux", associatedWords: [] },
  { word: "Sœur", associatedWords: [] },
  { word: "Table", associatedWords: [] },
  { word: "Tableau", associatedWords: [] },
  { word: "Tablier", associatedWords: [] },
  { word: "Tabouret", associatedWords: [] },
  { word: "Tache", associatedWords: [] },
  { word: "Taille", associatedWords: [] },
  { word: "Tailler", associatedWords: [] },
  { word: "Talon", associatedWords: [] },
  { word: "Tambour", associatedWords: [] },
  { word: "Taper", associatedWords: [] },
  { word: "Taper Sur", associatedWords: [] },
  { word: "Tapis", associatedWords: [] },
  { word: "Tard", associatedWords: [] },
  { word: "Tarte", associatedWords: [] },
  { word: "Tartine", associatedWords: [] },
  { word: "Tas", associatedWords: [] },
  { word: "Tasse", associatedWords: [] },
  { word: "Taupe", associatedWords: [] },
  { word: "Temps", associatedWords: [] },
  { word: "Tempête", associatedWords: [] },
  { word: "Tendre", associatedWords: [] },
  { word: "Tenir", associatedWords: [] },
  { word: "Tente", associatedWords: [] },
  { word: "Terminer", associatedWords: [] },
  { word: "Terrain", associatedWords: [] },
  { word: "Terre", associatedWords: [] },
  { word: "Terrible", associatedWords: [] },
  { word: "Terrier", associatedWords: [] },
  { word: "Thermomètre", associatedWords: [] },
  { word: "Thé", associatedWords: [] },
  { word: "Ticket", associatedWords: [] },
  { word: "Tige", associatedWords: [] },
  { word: "Tigre", associatedWords: [] },
  { word: "Timbre", associatedWords: [] },
  { word: "Tirer", associatedWords: [] },
  { word: "Tiroir", associatedWords: [] },
  { word: "Tissu", associatedWords: [] },
  { word: "Toboggan", associatedWords: [] },
  { word: "Toilette", associatedWords: [] },
  { word: "Toit", associatedWords: [] },
  { word: "Tomate", associatedWords: [] },
  { word: "Tomber", associatedWords: [] },
  { word: "Tonnerre", associatedWords: [] },
  { word: "Torchon", associatedWords: [] },
  { word: "Tordu", associatedWords: [] },
  { word: "Tortue", associatedWords: [] },
  { word: "Toucher", associatedWords: [] },
  { word: "Toujours", associatedWords: [] },
  { word: "Tour", associatedWords: [] },
  { word: "Tourner", associatedWords: [] },
  { word: "Tournevis", associatedWords: [] },
  { word: "Tousser", associatedWords: [] },
  { word: "Tout de suite", associatedWords: [] },
  { word: "Tracteur", associatedWords: [] },
  { word: "Train", associatedWords: [] },
  { word: "Traire", associatedWords: [] },
  { word: "Trait", associatedWords: [] },
  { word: "Trampoline", associatedWords: [] },
  { word: "Tranche", associatedWords: [] },
  { word: "Tranquille", associatedWords: [] },
  { word: "Transparent", associatedWords: [] },
  { word: "Transpirer", associatedWords: [] },
  { word: "Transporter", associatedWords: [] },
  { word: "Travail", associatedWords: [] },
  { word: "Travailler", associatedWords: [] },
  { word: "Travaux", associatedWords: [] },
  { word: "Traverser", associatedWords: [] },
  { word: "Traîneau", associatedWords: [] },
  { word: "Traîner", associatedWords: [] },
  { word: "Trembler", associatedWords: [] },
  { word: "Tremper", associatedWords: [] },
  { word: "Tricher", associatedWords: [] },
  { word: "Tricot", associatedWords: [] },
  { word: "Tricoter", associatedWords: [] },
  { word: "Tricycle", associatedWords: [] },
  { word: "Trier", associatedWords: [] },
  { word: "Triste", associatedWords: [] },
  { word: "Trois", associatedWords: [] },
  { word: "Troisième", associatedWords: [] },
  { word: "Trompette", associatedWords: [] },
  { word: "Trop", associatedWords: [] },
  { word: "Trottoir", associatedWords: [] },
  { word: "Trou", associatedWords: [] },
  { word: "Trouer", associatedWords: [] },
  { word: "Trous", associatedWords: [] },
  { word: "Trousse", associatedWords: [] },
  { word: "Trouver", associatedWords: [] },
  { word: "Trésor", associatedWords: [] },
  { word: "Tube", associatedWords: [] },
  { word: "Tuer", associatedWords: [] },
  { word: "Tulipe", associatedWords: [] },
  { word: "Tunnel", associatedWords: [] },
  { word: "Tuyau", associatedWords: [] },
  { word: "Tâter", associatedWords: [] },
  { word: "Télécommande", associatedWords: [] },
  { word: "Téléphone", associatedWords: [] },
  { word: "Téléphoner", associatedWords: [] },
  { word: "Télévision", associatedWords: [] },
  { word: "Téter", associatedWords: [] },
  { word: "Tête", associatedWords: [] },
  { word: "Tôt", associatedWords: [] },
  { word: "Un", associatedWords: [] },
  { word: "Uniforme", associatedWords: [] },
  { word: "Univers", associatedWords: [] },
  { word: "Usine", associatedWords: [] },
  { word: "Usé", associatedWords: [] },
  { word: "Utile", associatedWords: [] },
  { word: "Vacances", associatedWords: [] },
  { word: "Vache", associatedWords: [] },
  { word: "Vague", associatedWords: [] },
  { word: "Vaisselle", associatedWords: [] },
  { word: "Valise", associatedWords: [] },
  { word: "Vase", associatedWords: [] },
  { word: "Vendre", associatedWords: [] },
  { word: "Vendredi", associatedWords: [] },
  { word: "Venir", associatedWords: [] },
  { word: "Vent", associatedWords: [] },
  { word: "Venter", associatedWords: [] },
  { word: "Ventre", associatedWords: [] },
  { word: "Ver", associatedWords: [] },
  { word: "Verre", associatedWords: [] },
  { word: "Vers", associatedWords: [] },
  { word: "Verser", associatedWords: [] },
  { word: "Vert", associatedWords: [] },
  { word: "Veste", associatedWords: [] },
  { word: "Viande", associatedWords: [] },
  { word: "Vide", associatedWords: [] },
  { word: "Vider", associatedWords: [] },
  { word: "Vieux", associatedWords: [] },
  { word: "Village", associatedWords: [] },
  { word: "Ville", associatedWords: [] },
  { word: "Vin", associatedWords: [] },
  { word: "Virage", associatedWords: [] },
  { word: "Vis", associatedWords: [] },
  { word: "Visage", associatedWords: [] },
  { word: "Visiter", associatedWords: [] },
  { word: "Vite", associatedWords: [] },
  { word: "Vitesse", associatedWords: [] },
  { word: "Vitre", associatedWords: [] },
  { word: "Vivant", associatedWords: [] },
  { word: "Vivre", associatedWords: [] },
  { word: "Voile", associatedWords: [] },
  { word: "Voir", associatedWords: [] },
  { word: "Voisin", associatedWords: [] },
  { word: "Voiture", associatedWords: [] },
  { word: "Voix", associatedWords: [] },
  { word: "Voler", associatedWords: [] },
  { word: "Volet", associatedWords: [] },
  { word: "Vouloir", associatedWords: [] },
  { word: "Voyage", associatedWords: [] },
  { word: "Voyager", associatedWords: [] },
  { word: "Véhicule", associatedWords: [] },
  { word: "Vélo", associatedWords: [] },
  { word: "Vétérinaire", associatedWords: [] },
  { word: "Vêtement", associatedWords: [] },
  { word: "Wagon", associatedWords: [] },
  { word: "Xylophone", associatedWords: [] },
  { word: "Yaourt", associatedWords: [] },
  { word: "Yeux", associatedWords: [] },
  { word: "Zigzag", associatedWords: [] },
  { word: "Zoo", associatedWords: [] },
  { word: "Zèbre", associatedWords: [] },
  { word: "Zéro", associatedWords: [] },
  { word: "À Côté", associatedWords: [] },
  { word: "À Droite", associatedWords: [] },
  { word: "À Gauche", associatedWords: [] },
  { word: "À La Mer", associatedWords: [] },
  { word: "À L’endroit", associatedWords: [] },
  { word: "À L’envers", associatedWords: [] },
  { word: "À Moitié", associatedWords: [] },
  { word: "Âge", associatedWords: [] },
  { word: "Âne", associatedWords: [] },
  { word: "Écarter", associatedWords: [] },
  { word: "Échanger", associatedWords: [] },
  { word: "Écharpe", associatedWords: [] },
  { word: "Échasse", associatedWords: [] },
  { word: "Échasses", associatedWords: [] },
  { word: "Échelle", associatedWords: [] },
  { word: "Éclabousser", associatedWords: [] },
  { word: "Éclair", associatedWords: [] },
  { word: "Éclairer", associatedWords: [] },
  { word: "Éclater", associatedWords: [] },
  { word: "École", associatedWords: [] },
  { word: "Écorce", associatedWords: [] },
  { word: "Écouter", associatedWords: [] },
  { word: "Écran", associatedWords: [] },
  { word: "Écraser", associatedWords: [] },
  { word: "Écrire", associatedWords: [] },
  { word: "Écriture", associatedWords: [] },
  { word: "Écureuil", associatedWords: [] },
  { word: "Égal", associatedWords: [] },
  { word: "Église", associatedWords: [] },
  { word: "Élastique", associatedWords: [] },
  { word: "Élever", associatedWords: [] },
  { word: "Élève", associatedWords: [] },
  { word: "Éléphant", associatedWords: [] },
  { word: "Énervé", associatedWords: [] },
  { word: "Énorme", associatedWords: [] },
  { word: "Épais", associatedWords: [] },
  { word: "Épaule", associatedWords: [] },
  { word: "Épingle", associatedWords: [] },
  { word: "Éplucher", associatedWords: [] },
  { word: "Épluchure", associatedWords: [] },
  { word: "Éponge", associatedWords: [] },
  { word: "Épée", associatedWords: [] },
  { word: "Équipe", associatedWords: [] },
  { word: "Étagère", associatedWords: [] },
  { word: "Étaler", associatedWords: [] },
  { word: "Étang", associatedWords: [] },
  { word: "Éteindre", associatedWords: [] },
  { word: "Éternuer", associatedWords: [] },
  { word: "Étoile", associatedWords: [] },
  { word: "Étroit", associatedWords: [] },
  { word: "Étude", associatedWords: [] },
  { word: "Étudier", associatedWords: [] },
  { word: "Été", associatedWords: [] },
  { word: "Évier", associatedWords: [] },
  { word: "Île", associatedWords: [] }
]);
